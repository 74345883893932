import {useContext} from "react";


import {STATIC_HOST_ACTION} from "../../../const";
import {AppContext} from "../../../store/context";
import {classNames} from "../../../helper";

import styles from './Action.module.css'
import {generatePath, Link} from "react-router-dom";
import {publicRoutes} from "../../../routing";
import arrow_right from "../../../static/icons/catalog/arrow_right.svg";
import {Button, RedButton} from "../../common/Buttons";

export const ActionCard = ({action, big}) => {
    const {context} = useContext(AppContext)
    const openModal = () => {
        context.modals.action.dispatch({type: "show", payload: action})
    }

    return <div className={classNames(styles.card, big ? styles.cardBig : '')}>
        <div className={styles.cardImage} onClick={() => openModal()}>
            <img src={STATIC_HOST_ACTION + action.image?.source} alt={action.image?.alt}/>
        </div>
        <div className={styles.cardInner}>
            <div className={styles.cardTitle}>{action.title}</div>
            <div className={styles.cardText}>{action.preview}</div>
            <div className={styles.cardButton} onClick={() => openModal()}>
                {context.settings.isMobile ? <img src={arrow_right} alt=""/> : "подробнее"}
            </div>
        </div>
    </div>
}

export const ActionCardMain = ({action, big, onSelect}) => {
    const {context} = useContext(AppContext)

    return <div className={classNames(styles.card, big ? styles.cardBig : '')}>
        <div className={styles.cardImage} onClick={() => onSelect(action)}>
            <img src={STATIC_HOST_ACTION + action.image?.source} alt={action.image?.alt}/>
        </div>
        <div className={styles.cardInner}>
            <div className={styles.cardTitle}>{action.title}</div>
            <div className={styles.cardText}>{action.preview}</div>
            <div className={styles.cardButton} onClick={() => onSelect(action)}>
                {context.settings.isMobile ? <img src={arrow_right} alt=""/> : "подробнее"}
            </div>
        </div>
    </div>
}

export const ActionMainBlock = ({onSelect}) => {
    const {context} = useContext(AppContext)

    return <>
        <div className={styles.actionsInner}>
            {context.common.state.actions[0] || false ?
                <ActionCardMain onSelect={onSelect} big={context.settings.isMobile}
                                action={context.common.state.actions[0]}/> : null}
            {context.common.state.actions[1] || false ?
                <ActionCardMain onSelect={onSelect} action={context.common.state.actions[1]}/> : null}
            {context.common.state.actions[2] || false ?
                <ActionCardMain onSelect={onSelect} action={context.common.state.actions[2]}/> : null}
        </div>
        <div className={styles.actionsButton}>
            {context.settings.isMobile
                ? <RedButton text={"смотреть все акции"} link={generatePath(publicRoutes.deliveryActions.path)}/>
                : <Link to={generatePath(publicRoutes.deliveryActions.path)}>
                    <div className={styles.actionsButtonText}>все акции</div>
                    <div>
                        <img src={arrow_right} alt=""/>
                    </div>
                </Link>}
        </div>
    </>
}