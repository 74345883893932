import {generatePath} from 'react-router-dom'
import {useContext} from "react";

import {WhiteButton} from "../../common/Buttons";
import {publicRoutes} from "../../../routing";
import {AppContext} from "../../../store/context";

import styles from './About.module.css'

import img1 from '../../../static/images/main/m1.png';
import img2 from '../../../static/images/main/m2.png';
import img3 from '../../../static/images/main/m3.png';

export const About = () => {
    const {context} = useContext(AppContext)

    return <div className={styles.container}>
        {context.settings.isMobile === false ? <div className={styles.imagesSection}>
            <div className={styles.image1}>
                <img src={img1} alt=""/>
            </div>
            <div className={styles.image2}>
                <img src={img2} alt=""/>
            </div>
            <div className={styles.image3}>
                <img src={img3} alt=""/>
            </div>
        </div> : null}
        <div className={styles.textSection}>
            <div className={styles.title}>
                <div className={styles.preHead}>우리 팀</div>
                <h2 className={styles.head}>о нас</h2>
            </div>
            {context.settings.isMobile ? <div className={styles.imagesSection}>
                <div className={styles.image1}>
                    <img src={img2} alt=""/>
                </div>
                <div className={styles.image2}>
                    <img src={img1} alt=""/>
                </div>
            </div> : null}
            <div className={styles.inner}>
                <div className={styles.lead}>
                    <div className={styles.leadTitle}>Более 10 лет</div>
                    <div className={styles.leadSubTitle}>готовим блюда корейской кухни</div>
                </div>
                <div className={styles.text}>
                    Пробуйте корейскую кухню, знакомьтесь с нашими традициями и историей,
                    а мы подарим ощущение настоящего гастрономического путешествия в Корею
                </div>
                <div className={styles.button}>
                    <WhiteButton text={'Подробнее'} link={generatePath(publicRoutes.about.path)}/>
                </div>
            </div>
        </div>
    </div>
};
