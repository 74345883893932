import {generatePath, useHistory} from "react-router-dom";
import {useContext, useState, useEffect} from "react";
import {BottomSheet} from "react-spring-bottom-sheet";
import InputMask from "react-input-mask";

import {AppContext} from "../../store/context";
import {classNames} from "../../helper";
import arrowRight from '../../static/icons/personal/arrow_right.svg'
import err from '../../static/icons/personal/error.svg'
import {publicRoutes} from "../../routing";
import cross from "../../static/icons/cross.svg";

import styles from "./Personal.module.css";
import commonStyles from "./Common.module.css";

export const ResponsivePersonalModal = () => {
    const {context} = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen = context.modals.personal.state.open

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 400)
        }
    }, [isOpen])

    const onDismiss = () => {
        context.modals.personal.dispatch({type: 'close'});
    };


    const Desktop = ({children}) => {
        return
    };


    return <div className={classNames(styles.modal, (open ? commonStyles.modals : ''), (open ? styles.modalOpen : ''))}
                style={{display: display ? "flex" : "none"}}>
        <div className={classNames(commonStyles.modalsCross, styles.cross)} onClick={() => onDismiss()}>
            <img src={cross} alt=""/>
        </div>
        <PersonalModal/>
    </div>
}

export const PersonalModal = () => {
    const {context} = useContext(AppContext)

    const [step, setStep] = useState(1)
    const [phone, setPhone] = useState(null)

    useEffect(() => {
        if (context.modals.personal.state.open) {
            setStep(1)
        }
    }, [context.modals.personal.state.open])

    const nextStep = (phone) => {
        setPhone(phone)
        setStep(2)
    }

    const prevStep = () => {
        setPhone(null)
        setStep(1)
    }

    return step === 1
        ? <PhoneSection next={nextStep}/>
        : <CodeSection phone={phone} back={prevStep}/>
}

const PhoneSection = ({next}) => {
    const {context} = useContext(AppContext)
    const [phone, setPhone] = useState(null)

    const sendCode = () => {
        context.processor.personal
            .authorize(phone)
            .then(res => {
                next(phone)
            })
            .catch(err => {
                context.toast.error(err.message)
            })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendCode()
        }
    }


    return <div className={styles.inner}>
        <div className={styles.title}>
            <div className={styles.titleText}>приветствуем вас</div>
            <div className={styles.titleSub}>
                <span>Аньён хасейо</span>
                <span>안녕하세요</span>
            </div>
        </div>
        <div className={styles.body}>
            <div className={styles.bodyText}>
                Авторизируйтесь в личный кабинет по номеру телефона:
            </div>
            <div className={styles.bodyInput}>
                <InputMask className={styles.input}
                           value={phone}
                           onKeyDown={handleKeyDown}
                           formatChars={{'9': '[0-9]', '7': '[7-8]'}}
                           onChange={e => setPhone(e.currentTarget.value)}
                           mask="+7 (999) 999-99-99"
                           placeholder={'Номер телефона'}
                           maskChar="_"/>
                <div className={styles.bodyButton} onClick={() => sendCode()}>
                    <span>продолжить</span>
                    <img src={arrowRight} alt=""/>
                </div>
            </div>
        </div>
        <div className={styles.agreement}>
            Нажимая кнопку "Продолжить", вы принимаете <a href="/confidential" target={"_blank"}>Соглашение об условиях
            доставки</a> и <a href="/confidential" target={"_blank"}>Политику обработки персональных данных</a>
        </div>
    </div>
}

const CodeSection = ({phone, back}) => {
    const {context} = useContext(AppContext)

    const [code, setCode] = useState(null)
    const [error, setError] = useState(null)

    const history = useHistory()

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            validateCode()
        }
    }

    const validateCode = () => {
        context.processor.personal
            .authorizeCheck(phone, code)
            .then(res => {
                context.customer.dispatch({type: "set", payload: res.customer})
                context.modals.personal.dispatch({type: "close"})
                history.push(generatePath(publicRoutes.personal.path))
            })
            .catch(err => {
                setError(err.message)
            })
    }

    const sendCode = () => {
        context.processor.personal
            .authorize(phone)
            .then(res => {
                setError(null)
                setCode(null)
            })
            .catch(err => {
                setError(err.message)
            })
    }

    return <div className={styles.inner}>
        <div className={styles.title}>
            <div className={styles.titleText}>приветствуем вас</div>
            <div className={styles.titlePhone}>
                <span>{phone}</span>
                <span onClick={() => back()}>изменить номер</span>
            </div>
        </div>
        <div className={styles.body}>
            <div className={styles.bodyText}>
                {error ? <div className={styles.bodyError}>
                    <img src={err} alt=""/>
                    <span>{error}</span>
                </div> : "Введите 4-х значный код из СМС"}
            </div>
            <div className={styles.bodyInput}>
                <InputMask className={styles.input}
                           value={code}
                           onKeyDown={handleKeyDown}
                           formatChars={{'9': '[0-9]'}}
                           onChange={e => setCode(e.currentTarget.value)}
                           mask="9 9 9 9"
                           placeholder={'Код'}
                           maskChar="X"/>
                <div className={styles.bodyButton} onClick={() => validateCode()}>
                    <span>войти</span>
                    <img src={arrowRight} alt=""/>
                </div>
            </div>
        </div>
        <Timer seconds={60} callback={() => sendCode()}/>
    </div>
}

const Timer = ({seconds, callback}) => {
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        if (!timeLeft) {
            return;
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const execCallback = () => {
        setTimeLeft(seconds)
        callback()
    }

    return <div className={styles.countdown}>
        {timeLeft
            ? <span className={styles.countdownBlock}>отправить код еще раз через {timeLeft} сек.</span>
            : <span onClick={() => {
                execCallback()
            }} className={styles.countdownAction}>Отправить код повторно</span>}
    </div>
};