import React from 'react'

import styles from './Kuksumania.module.css';
import arrowRed from "../../../../static/images/landing/arrow_red.png";

export const Kuksumania = () => {

    return <div className={styles.container}>
        <div className={styles.kuksumania}>
            <h1 className={styles.kuksumania__title}>Куксумания</h1>
            <div className={styles.kuksumania__steps}>
                <div className={styles.kuksumania__step}>
                    <p className={styles.kuksumania__stepTitle}>Заказывайте <br /> куксу</p>
                    <img src={arrowRed} alt={'arrow'} />
                </div>
                <div className={styles.kuksumania__step}>
                    <p className={styles.kuksumania__stepTitle}>Собирайте <br /> наклейки</p>
                    <img src={arrowRed} alt={'arrow'} />
                </div>
                <div className={styles.kuksumania__step}>
                    <p className={styles.kuksumania__stepTitle}>Получайте подарки <br /> и учавствуйте в розыгрыше призов!</p>
                    <img src={arrowRed} alt={'arrow'} />
                </div>
            </div>
            <p className={styles.kuksumania__description}>
                1 куксу = 1 наклейка = 1 купон для розыгрыша призов
            </p>
            <p className={styles.kuksumania__sub_description}>
                За каждые <b>5 наклеек</b> можно забрать подарок лично в любом из ресторанов КОРЕАНА, КОРЕАНА LIGHT или KIMCHI TO GO.
            </p>
            <p className={styles.kuksumania__sub_description}>
                <b>25 августа на фестивале KUKSU DAY</b> вы сможете обменять наклейки на купоны, чтобы поучаствовать в розыгрыше крупных призов от партнеров
            </p>
        </div>
    </div>
}
