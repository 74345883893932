import React from 'react'
import {AppContext} from "../store/context";
import img from '../static/icons/404.svg'
import styles from './Page404.module.css'
import {generatePath, Link} from "react-router-dom";
import {publicRoutes} from "../routing";
import {RedButton, WhiteButton} from "../components/common/Buttons";

export default class Page404 extends React.Component {
    constructor() {
        super()
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <div className={styles.container}>
            <img src={img} alt=""/>
            <div className={styles.buttons}>
                <RedButton link={generatePath(publicRoutes.delivery.path)} text={"смотреть меню"}/>
                <WhiteButton link={generatePath(publicRoutes.main.path)} text={"на главную"}/>
            </div>
        </div>
    }
}

Page404.contextType = AppContext;