import React from 'react'

import styles from './Kuksuday.module.css';

import kuksudayImg1 from '../../../../static/images/landing/kuksuday/kuksuday_1.png';
import kuksudayImg2 from '../../../../static/images/landing/kuksuday/kuksuday_2.png';

import pinIcon from '../../../../static/images/landing/kuksuday/pin_icon.png';
import timeeIcon from '../../../../static/images/landing/kuksuday/time_icon.png';

export const Kuksuday = () => {

    return <div className={styles.container}>
        <div className={styles.kuksuday}>
            <div className={styles.kuksuday__info}>
                <div className={styles.kuksuday__date}>
                    <h2 className={styles.kuksuday__dateTitle}>kuksu day</h2>
                    <div className={styles.kuksuday__details}>
                        <div className={styles.kuksuday__detailsItem}>
                            <img src={timeeIcon} alt={''} />
                            <div className={styles.kuksuday__detailsInfo}>
                                <p className={styles.kuksuday__detailsTitle}>25 АВГУСТА</p>
                                <p className={styles.kuksuday__detailsSubtitle}>начало с 13:00 до 21:00</p>
                            </div>
                        </div>
                        <div className={styles.kuksuday__detailsItem}>
                            <img src={pinIcon} alt={''} />
                            <div className={styles.kuksuday__detailsInfo}>
                                <p className={styles.kuksuday__detailsTitle}>Никольские ряды</p>
                                <a href="https://maps.app.goo.gl/b1TstXFCQ3zyLRg38" target="_blank">
                                    <p className={styles.kuksuday__detailsSubtitle}>ул. Садовая 62, вход с Садовой</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.kuksuday__description}>
                    <h3 className={styles.kuksuday__descriptionTitle}>Приходите на наш грандиозный корейский фестиваль!</h3>
                    <div className={styles.kuksuday__descriptionText}>
                        <p>Куксу-баттл</p>
                        <p>бесплатные мастер-классы</p>
                        <p>фотозона с ханбоками</p>
                        <p>k-pop art market</p>
                        <p>к-поп танцы</p>
                        <p>паблик толк с блогерами</p>
                        <p>вкусная еда</p>
                    </div>
                   <p className={styles.kuksuday__subtitleDescription}>
                       Никто не останется голодным! На фестивале будут стоять корнеры, где наши повара будут стараться для вас готовить самые яркие и вкусные эмоциональные блюда!
                   </p>
                </div>
            </div>
            <div className={styles.kuksuday__images}>
                <img src={kuksudayImg1} alt={'kuksuday'} />
                <img src={kuksudayImg2} alt={'kuksuday'} />
            </div>
        </div>
    </div>
}
