import React from 'react'

import styles from './Merch.module.css';

import cards from '../../../../static/images/landing/cards.png';

export const Merch = () => {

    return <div className={styles.container}>
        <div className={styles.merch__wrapper}>
            <div className={styles.merch__data}>
                <div className={styles.merch}>
                    <div className={styles.merch__wrapperInfo}>
                        <h3 className={styles.merch__title}>ГАРАНТИРОВАННЫЕ ПРИЗЫ ЗА 5 НАКЛЕЕК!</h3>
                        <div className={styles.merch__info}>
                            <div className={styles.merch__infoList}>
                                <p>КОРЕАНА:</p>
                                <p>металлические миски</p>
                                <p>корейские палочки</p>
                                <p>кухонные приборы</p>
                                <p>шоппер</p>
                            </div>
                        </div>
                        <div className={styles.merch__info}>
                            <div className={styles.merch__infoList}>
                                <p>КОРЕАНА LIGHT:</p>
                                <p>шоппер</p>
                                <p>бутылка для воды</p>
                                <p>экостакан</p>
                            </div>
                        </div>
                        <div className={styles.merch__info}>
                            <div className={styles.merch__infoList}>
                                <p>KIMCHI TO GO:</p>
                                <p>брелок и сумка (дизайн на выбор)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.merch__cards}>
                <img src={cards} alt={'cards'} />
            </div>
        </div>
    </div>
}
