import React, {useContext, useEffect, useRef, useState} from 'react'
import {generatePath} from 'react-router-dom'

import {WhiteButton} from "../../common/Buttons";
import {publicRoutes} from "../../../routing";

import arrow from '../../../static/icons/arrow_right_hits.svg'
import arrowDown from '../../../static/icons/arrow_down_hits.svg'

import styles from './Hits.module.css'
import {AppContext} from "../../../store/context";
import {STATIC_HOST_PRODUCT} from "../../../const";

export const Hits = () => {
    const {context} = useContext(AppContext)

    const [items, setItems] = useState(context.common.state.hits)
    const [selected, setSelected] = useState(context.common.state.hits[0])
    const [pos, setPos] = useState(0);
    const ref = useRef()

    useEffect(() => {
        setSelected(items[0])
        setItems(items.slice(1))
    }, [])

    const changeSlide = (el) => {
        const it = items
        const i = it.findIndex((e) => e.id === el.id)

        it[i] = selected

        setItems(it)
        setSelected(el)
    }

    const scroll = () => {
        const step = 180 + 13
        const maxPos = step * 4

        const newPos = pos + step

        setPos(newPos < maxPos ? newPos : 0)

        ref?.current?.scrollTo({
            top: newPos < maxPos ? newPos : 0,
            behavior: "smooth"
        })
    }

    return <div className={styles.container}>
        <div className={styles.title}>
            <div className={styles.preHead}>한국 요리의 히트작</div>
            <h2 className={styles.head}>хиты корейской кухни</h2>
        </div>
        <div className={styles.inner}>
            <div className={styles.preview}>
                <div className={styles.previewImage}>
                    <img src={STATIC_HOST_PRODUCT + selected?.image?.source}
                         alt={selected?.image?.alt}/>
                </div>
                <div className={styles.previewTextHolder}>
                    <div className={styles.previewTitle}>
                        <div className={styles.previewTitleBullet}>베스트셀러</div>
                        <div className={styles.previewTitleText}>{selected?.title}</div>
                    </div>
                    <div className={styles.previewText}>
                        {selected?.content.preview}
                    </div>
                    <div className={styles.previewButton}>
                        <WhiteButton text={'Подробнее'}
                                     link={generatePath(publicRoutes.deliveryCategory.path[1], {
                                         slug: selected?.category.slug,
                                         product: selected?.slug
                                     })}/>
                    </div>
                </div>
            </div>
            <div className={styles.nextButton}>
                <img src={arrow} alt=""/>
            </div>
            <div className={styles.gallery}>
                <div className={styles.galleryInner} ref={ref}>
                    {items.map(el => {
                        return <div className={styles.galleryImage} onClick={() => changeSlide(el)}>
                            <img src={STATIC_HOST_PRODUCT + el.image?.source} alt={el.image?.alt}/>
                        </div>
                    })}
                </div>
                {context.settings.isMobile
                    ? <div onClick={() => scroll()} className={styles.galleryScroll}>
                        <img src={arrowDown} alt=""/>
                    </div>
                    : null}
            </div>
        </div>
    </div>
}
