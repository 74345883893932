import React, {useContext} from "react";
import styles from "./FiveLegends.module.css";

import {AppContext} from "../../store/context";

import dish1 from "../../static/images/5-legends/dish1.png";
import dish2 from "../../static/images/5-legends/dish2.png";
import dish3 from "../../static/images/5-legends/dish3.png";
import dish4 from "../../static/images/5-legends/dish4.png";
import dish5 from "../../static/images/5-legends/dish5.png";
import {RedButton} from "../../components/common/Buttons";

export default class FiveLegends extends React.Component {
    constructor() {

        super()

        this.state = {
            dishes: [
                {
                    img: dish3,
                    title: 'Пуде рамен',
                    suptitle: '부대라면',
                    description: 'Пуде Рамён — современная версия этого сытного мясного блюда, которое мы готовим со свининой и лапшой на густом говяжьем бульоне. Для пикантности добавляем кимчи. История блюда началась в 1950-х. Корейское слово «пуде» переводится как «армейский». В те годы Корея получала помощь от американской стороны в виде ветчины, сосисок, тушенки и лапши быстрого приготовления. Корейскому народу полюбилось сочетание ингредиентов, поэтому они стали использовать их в дальнейшем с традиционными специями.',
                    price: 553,
                    oldPrice: 790,
                },
                {
                    img: dish5,
                    title: 'Тямпон',
                    suptitle: '짬뽕',
                    description: 'Ценители морских продуктов, это блюдо для вас!  Тямпон был создан китайскими иммигрантами в Японии. Он объединяет элементы кухонь трёх стран - Китая, Японии и Кореи. Мы усовершенствовали рецептуру блюда, сделали его густым и увеличили количество деликатесов. Готовим тямпон с кальмарами, тигровыми креветками и двумя видами мидий на умамном рыбном бульоне. Добавляем свежие кабачки, морковь и другие овощи, а для остроты и пряности кладем чеснок и красный перец.',
                    price: 693,
                    oldPrice: 990,
                },
                {
                    img: dish1,
                    title: 'Кальби Тим',
                    suptitle: '갈비찜',
                    description: 'Это традиционное корейское блюдо из говяжьих ребрышек считается деликатесным, поэтому его часто готовят на особые мероприятия. Название блюда происходит от слова «кальби» (갈비), что значит «ребра», и «тим» (찜) — «тушеный». Мы маринуем мраморные говяжьи ребра в сладковатом соусе с чили, имбирем и чесноком. А чтобы мясо стало мягким и ароматным, долго его тушим. Подаем с бататовой лапшой, овощами и зеленым луком.',
                    price: 833,
                    oldPrice: 1190,
                },
                {
                    img: dish2,
                    title: 'Моксаль Чумулок',
                    suptitle: '목살주물럭',
                    description: 'Знаменитое корейское блюдо из свинины - аналог популярного пульгоги из говядины! Для сочности мясо маринуем в пряной смеси из соевого соуса, чеснока, кунжутного масла и паприки. Благодаря быстрой обжарки получаем румяную корочку. Готовое блюдо подаем со свежими листьями салата: в них необходимо заворачивать кусочки мяса и угощать друг друга. По желанию добавьте к блюду чеснок, перцовую пасту или другой пикантный соус.',
                    price: 595,
                    oldPrice: 850,
                },
                {
                    img: dish4,
                    title: 'Токпокки Original',
                    suptitle: '핫 떡볶이',
                    description: 'Эта культовая корейская закуска из аппетитных рисовых палочек завоевала сердца по всему миру. Наш чиз токпокки давно стал фаворитом меню, а для истинных ценителей остроты мы предлагаем токпокки в пряном соусе, который готовим из красного перца кочуджан. Чем острее, тем вкуснее — так считают корейцы! А чтобы сделать вас более сытыми и довольными, мы добавили в это горячее блюдо копченые сосиски и яйцо — согревайтесь и наслаждайтесь!',
                    price: 343,
                    oldPrice: 490,
                },
            ],
        }
    }

    render() {
        return <>
             <main className={styles.main}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <h1 className={styles.head}>ВОЗРОЖДЕНИЕ ЛЕГЕНД</h1>
                    </div>
                    <div className={styles.textContainer}>
                        <h3 className={styles.textTitle}>ВКУСЫ, КОТОРЫЕ МЫ ДОВЕЛИ ДО СОВЕРШЕНСТВА!</h3>
                        <p className={styles.text}>
                            В год нашего 10-летнего юбилея мы выбрали для вас 5 самых ярких блюд в меню, рецепты которых оттачивали и совершенствовали все это время. <br /> <br />
                            Новые яркие вкусы со скидкой 30% до 15 декабря 2024г. Для получения скидки необходимо подписаться на наши соц сети: <a href='https://vk.com/koreana.rest'>koreana.rest</a> <br />
                        </p>
                        <p className={styles.textBold}>Дегустируйте, оценивайте, влюбляйтесь по-новому!</p>
                    </div>
                </div>
                 <hr />
                 <div className={styles.container}>
                    {this.state.dishes.map(item => (
                        <article className={styles.article}>
                            <div className={styles.articleCol}>
                                <div className={styles.articleInfo}>
                                    <p className={styles.articleSuptitle}>{ item.suptitle}</p>
                                    <p className={styles.articleTitle}>{ item.title}</p>
                                    <p className={styles.articleDescription}>{ item.description}</p>
                                </div>
                            </div>
                            <div className={styles.articleCol}>
                                <div className={styles.articleImg}>
                                    <img src={item.img} alt={item.title} />
                                    <div className={styles.articlePrice}>
                                        <span>{ item.price }₽</span><span>{item.oldPrice}₽</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                    ))}

                    <Button />
                </div>
             </main>
        </>
    }
}

const Button = () => {
    const {context} = useContext(AppContext)
    return <>
            <RedButton
                className={styles.bookButton}
                onClick={() => context.modals.restaurant.dispatch({type: "show",})}
                text={'БРОНИРУЙТЕ СТОЛ В РЕСТОРАНЕ И ПОПРОБУЙТЕ НОВЫЕ ГОРИЗОНТЫ ВКУСА!'}/>
    </>
}

FiveLegends.contextType = AppContext;
