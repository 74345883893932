import React from 'react'

import {AppContext} from "../store/context";
import {Team} from "../components/sections/main/Team/Team";
import {Kimchi} from "../components/sections/main/Kimchi/Kimchi";

export default class Lubov extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <>
            <Team />
            <Kimchi />
        </>
    }
}

Lubov.contextType = AppContext;
