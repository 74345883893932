import {Base} from "./base.api";
import {API_HOST} from "../const";
import {addToCartGoal, applyPromocodeGoal, checkoutGoal} from "../analytics";

const BASE_URL = `${API_HOST}/cart`;

export class Cart extends Base {
    cart() {
        return this.fetchJSON(`${BASE_URL}/`).then(response => {
            return response.data
        })
    }

    addProduct({id, option, uid}) {
        id = id || null
        option = option || null
        uid = uid || null

        return this.fetchJSON(`${BASE_URL}/product/add`, {
            method: 'POST',
            body: JSON.stringify({id: id, option: option, uid: uid})
        }).then(response => {
            addToCartGoal()

            return response.data
        })
    }

    sendOtp(phone){
        return this.fetchJSON(`${BASE_URL}/verify`, {
            method: 'POST',
            body: JSON.stringify({phone: phone})
        }).then(response => {
            return response.data
        })
    }

    verifyOtp(phone, code){
        return this.fetchJSON(`${BASE_URL}/verify/code`, {
            method: 'POST',
            body: JSON.stringify({phone: phone, code: code})
        }).then(response => {
            return response.data
        })
    }

    removeProduct({uid}) {
        return this.fetchJSON(`${BASE_URL}/product/remove`, {
            method: 'POST',
            body: JSON.stringify({uid: uid})
        }).then(response => {
            return response.data
        })
    }

    clearProduct({uid}) {
        return this.fetchJSON(`${BASE_URL}/product/clear`, {
            method: 'POST',
            body: JSON.stringify({uid: uid})
        }).then(response => {
            return response.data
        })
    }

    checkout(request) {
        return this.fetchJSON(`${BASE_URL}/checkout`, {
            method: 'POST',
            body: JSON.stringify(request)
        }).then(response => {
            checkoutGoal()

            return response.data
        })
    }

    applyPromocode(request) {
        return this.fetchJSON(`${BASE_URL}/promocode`, {
            method: 'POST',
            body: JSON.stringify(request)
        }).then(response => {
            applyPromocodeGoal()

            return response.data
        })
    }

    clearPromocode(request) {
        return this.fetchJSON(`${BASE_URL}/promocode`, {
            method: 'DELETE',
            body: JSON.stringify(request)
        }).then(response => {
            return response.data
        })
    }
}