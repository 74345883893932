import React from 'react'

import styles from './Prizes.module.css';

import partners from '../../../../static/images/landing/partners.png';

export const Prizes = () => {

    return <div className={styles.container}>
            <div className={styles.prizes}>
                <div className={styles.prizes__wrapper}>
                    <div className={styles.prizes__wrapperInfo}>
                        <h3 className={styles.prizes__title}>РОЗЫГРЫШ ПРИЗОВ ЗА КУПОНЫ <br /> на фестивале kuksu day</h3>
                        <div className={styles.prizes__info}>
                            <div className={styles.prizes__infoList}>
                                <p>холодильник от LG</p>
                                <p>Электрогриль BBQ Master KITFORT</p>
                                <p>Автомобильный холодильник KITFORT</p>
                                <p>Электрогриль KITFORT</p>
                            </div>
                            <p className={styles.prizes__infoDescription}>
                                Акция проходит с 17 июня по 24 августа во всех ресторанах «Кореана», «Кореана Light» и «Kimchi to go» в г. Санкт-Петербург.
                                Не действует на доставку и другие регионы
                            </p>
                        </div>
                    </div>
                    <img className={styles.prizes__imgPartners} src={partners} alt={'partners'} />
                </div>
            </div>
        </div>
}
