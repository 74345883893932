import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../store/context';
import { classNames } from '../../helper';
import cross from '../../static/icons/cross.svg';
import styles from './GiftCertificate.module.css';
import InputMask from 'react-input-mask';
import { RedButton } from '../common/Buttons';

export const ResponsiveGiftCertificateModal = () => {
    const { context } = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState({
        name: '',
        phone: '',
        email: '',
        total: '',
    });

    const isOpen = context.modals.giftCertificate.state.open;

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setSuccess(false);
            setData({
                name: '',
                phone: '',
                email: '',
                total: '',
            });
            setTimeout(() => {
                setOpen(isOpen);
            }, 10);
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen);
            }, 400);
        }
    }, [isOpen]);

    const onDismiss = () => {
        context.modals.giftCertificate.dispatch({ type: 'close' });
    };

    const handleSubmitClick = () => {
        let phoneUnmasked = data.phone?.match(/\d+/g);
        phoneUnmasked = phoneUnmasked ? phoneUnmasked.join('') : '';

        if (!data.name || data.name.length < 1) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (!data.email) {
            context.toast.error('Пожалуйста, укажите email');
            return;
        }

        if (!data.total) {
            context.toast.error('Пожалуйста, укажите номинал');
            return;
        }

        context.processor.form.giftCertificate(data).then(res => {
            setSuccess(true);
            setData({
                name: '',
                phone: '',
                email: '',
                total: '',
            });
        }).catch(err => {
            context.toast.error(err.message)
        })
    };

  return (
    <div
        className={classNames(styles.modal, open ? styles.modalOpen : '')}
        style={{ display: display ? 'flex' : 'none' }}
    >
        <button
            type="button"
            className={styles.cross}
            onClick={() => onDismiss()}
        >
            <img src={cross} alt="" />
        </button>
        <div className={styles.inner}>
            <div className={styles.innerTop}>
                <span className={styles.supTitle}>기프트 카드</span>
                <h2 className={styles.title}>
                    Дарите своим <br/> близким нечто  <br/> особенное!
                </h2>
                <p className={styles.text}>
                    <b>Подарочные сертификаты</b> - это&nbsp;не&nbsp;просто подарок, это настоящее
                    гастрономическое путешествие и&nbsp;незабываемые впечатления.
                </p>
                <p className={styles.text}>
                    Пусть ваш подарок станет тем светлым моментом, который согреет
                    сердца ваших близких!
                </p>
                <p className={styles.textColor}>Как приобрести сертификат:</p>
                <ol className={styles.list}>
                    <li className={styles.listItem}>Заполните все поля ниже</li>
                    <li className={styles.listItem}>
                    Дождитесь звонка специалиста, он&nbsp;поможет Вам провести оплату и
                    отправит электронный сертификат на&nbsp;почту
                    </li>
                    <li className={styles.listItem}>
                        Подарите сертификат или проведите время ввместе
                    </li>
                </ol>
            </div>
            <div className={styles.innerBottom}>
                <p className={styles.textForm}>
                    Вы также можете купить бумажный сертификат в любом из наших
                    ресторанов.
                </p>
                <form className={styles.form}>
                    <div className={styles.row}>
                        <input
                            type="text"
                            onChange={(evt) => setData({ ...data, name: evt.currentTarget.value })}
                            placeholder="Имя"
                            aria-label="Имя"
                            autoComplete="name"
                            value={data.name}
                            className={styles.input}
                        />
                    </div>
                    <div className={`${styles.row} ${styles.rowColumn}`}>
                        <InputMask
                            className={styles.input}
                            value={data.phone}
                            formatChars={{
                                9: '[0-9]',
                                7: '[7-8]',
                            }}
                            onChange={(evt) => setData({ ...data, phone: evt.currentTarget.value })}
                            mask="+7 (999) 999-99-99"
                            placeholder="Телефон"
                            aria-label="Телефон"
                            maskChar="_"
                        />
                        <input
                            type="email"
                            onChange={(evt) => setData({ ...data, email: evt.currentTarget.value })}
                            placeholder="E-mail"
                            aria-label="E-mail"
                            autoComplete="email"
                            value={data.email}
                            className={styles.input}
                        />
                    </div>
                    <div className={`${styles.row} ${styles.rowLast}`}>
                        <input
                            type="number"
                            min={0}
                            step={100}
                            onChange={(evt) => setData({ ...data, total: evt.currentTarget.value })}
                            placeholder="Номинал"
                            aria-label="Номинал"
                            value={data.total}
                            className={styles.input}
                        />
                    </div>
                    <RedButton text={'Отправить'} onClick={() => handleSubmitClick()} />
                    {success && <SuccessTextForm />}
                </form>
            </div>
        </div>
    </div>
  );
};

const SuccessTextForm = () => {
  return (
    <p className={styles.textSuccess}>Спасибо! Мы скоро с Вами свяжемся</p>
  );
};
