import React, {createRef} from "react";
import InputMask from "react-input-mask";

import {AppContext} from "../../store/context";
import {RedButton} from "../../components/common/Buttons";
import {classNames} from "../../helper";
import {Checkbox, Question, QuestionStars, QuestionTextarea} from "../../components/common/Form";

import back from '../../static/icons/back.svg'
import otzyv from '../../static/images/forms/otzyv.jpg'

import styles from "./FeedbackRestaurant.module.css";


export default class FeedbackRestaurant extends React.Component {
    constructor() {
        super();
        this.form = createRef()

        this.state = {
            proceed: false,
            step: 1,

            name: null,
            phone: null,
            restaurant: null,

            overall: 1,
            kitchen: 1,
            obsluzh: 1,
            recommend: 1,
            comment: '',
        }
    }

    prevStep() {
        window.scrollTo({top: this.form.current.offsetTop - 20, left: 0, behavior: "smooth"});

        this.setState({step: this.state.step - 1})
    }

    progressClass() {
        switch (this.state.step) {
            case 1:
                return classNames(styles.progress, styles.progress_1)
            case 2:
                return classNames(styles.progress, styles.progress_2)
            case 3:
                return classNames(styles.progress, styles.progress_3)
        }
    }

    nextStep() {
        switch (this.state.step) {
            case 1:
                const phoneUnmasked = this.state.phone?.match(/\d+/g).join('') || "";
                if (phoneUnmasked.length < 11) {
                    this.context.context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
                    return;
                }

                if (!this.state.name) {
                    this.context.context.toast.error('Пожалуйста, проверьте корректность введенного имени');
                    return;
                }

                if (!this.state.restaurant) {
                    this.context.context.toast.error('Пожалуйста, укажите ресторан');
                    return;
                }


                break;
        }


        window.scrollTo({top: this.form.current.offsetTop - 20, left: 0, behavior: "smooth"});

        this.setState({step: this.state.step + 1})
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    send() {
        this.context.context.processor.form
            .feedbackRestaurant(this.state)
            .then(res => this.setState({proceed: true}))
            .catch(err => this.context.context.toast.error(err.message))
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleText}>обратная связь: рестораны</div>
                <div className={styles.titleSub}>Обратная связь помогает нам стать лучше и <br/>сделать посещение более
                    комфортным для Вас
                </div>
            </div>
            <div className={styles.inner}>
                <div className={classNames(styles.form, this.state.proceed ? styles.formProceed : null)}
                     ref={this.form}>
                    <div className={styles.formSteps}>
                        <img src={otzyv} alt=""/>
                    </div>
                    {this.state.proceed ?
                        <div className={styles.typo}>
                            <div className={styles.head}>
                                <span>Спасибо, что помогаете нам улучшать сервис! </span>
                            </div>
                            <div className={styles.text}>
                                Вы также можете стать нашим <span>Тайным Агентом</span> и совместить приятное с полезным:
<br/><br/>
                                В качестве благодарности за проверку ресторана Вы получите сертификат на 1000₽,
                                который можно потратить в любом заведении нашей сети.
                            </div>
                            <div className={styles.button}>
                                <RedButton onClick={() => this.props.history.push("/agent")} text={"стать тайным агентом"}/>
                            </div>
                        </div> : <div className={styles.survey}>
                            <div className={styles.progressBar}>
                                <div className={styles.stepTitle}>заполнено {this.state.step - 1}/3</div>
                                <div className={this.progressClass()}/>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 1 ? styles.active : '')}>
                                <div className={styles.common}>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionRest)}>
                                        <div className={styles.commonTitle}>Адрес ресторана *</div>
                                        <div className={styles.commonAnswer}>
                                            {this.context.context.common.state.restaurants.map(el => {
                                                return <div className={styles.commonCheckbox}>
                                                    <Checkbox checked={this.state.restaurant === el.name}
                                                              onClick={() => this.setState({restaurant: el.name})}
                                                              text={el.name}/>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionName)}>
                                        <div className={styles.commonTitle}>Ваше имя *</div>
                                        <div className={styles.commonAnswer}>
                                            <input type="text" value={this.state.name} className={styles.input}
                                                   placeholder={"Имя"}
                                                   onChange={(e) => this.setState({name: e.currentTarget.value})}/>
                                        </div>
                                    </div>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionPhone)}>
                                        <div className={styles.commonTitle}>Номер телефона *</div>
                                        <div className={styles.commonAnswer}>
                                            <InputMask className={styles.input}
                                                       value={this.state.phone}
                                                       formatChars={{
                                                           '9': '[0-9]',
                                                           '7': '[7-8]'
                                                       }}
                                                       readonly={true}
                                                       mask="+7 (999) 999-99-99"
                                                       placeholder={'Номер телефона'}
                                                       onChange={(e) => this.setState({phone: e.currentTarget.value})}
                                                       maskChar="_"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.nextButton}>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 2 ? styles.active : '')}>
                                <div className={styles.questions}>
                                    <QuestionStars value={this.state.overall}
                                                   max={5}
                                                   onSelect={(val) => this.setState({overall: val})}
                                                   text={"Оцените общее впечатление о посещении"}/>
                                    <QuestionStars value={this.state.kitchen}
                                                   max={5}
                                                   onSelect={(val) => this.setState({kitchen: val})}
                                                   text={"Оцените нашу кухню"}/>
                                    <QuestionStars value={this.state.obsluzh}
                                                   max={5}
                                                   onSelect={(val) => this.setState({obsluzh: val})}
                                                   text={"Оцените качество обслуживания"}/>
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 3 ? styles.active : '')}>
                                <div className={styles.questions}>
                                    <QuestionStars value={this.state.recommend}
                                                   max={10}
                                                   onSelect={(val) => this.setState({recommend: val})}
                                                   text={"Оцените, насколько вероятно, что Вы порекомендуете заведение своим друзьям и знакомым"}/>
                                    <QuestionTextarea text={"Комментарии к посещению"}
                                                      value={this.state.comment}
                                                      onChange={(e) => this.setState({comment: e.currentTarget.value})}/>
                                </div>
                                <div className={classNames(styles.nextButton, styles.finalButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.send()} text={"отправить оценку"}/>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    }
}

FeedbackRestaurant.contextType = AppContext;