import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/form`;

export class Form extends Base {
    booking(data) {
        return this.fetchJSON(`${BASE_URL}/booking`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }

    agentReport(data) {
        return this.fetchJSON(`${BASE_URL}/agent-report`, {
            method: 'POST',
            body: data
        }, true).then(response => {
            return response.data
        })
    }

    agentDeliveryReport(data) {
        return this.fetchJSON(`${BASE_URL}/agent-delivery-report`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }

    agentRequest(data) {
        return this.fetchJSON(`${BASE_URL}/agent-request`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }

    callback(data) {
        return this.fetchJSON(`${BASE_URL}/callback`, {
            method: 'POST',
            body: data
        }, true).then(response => {
            return response.data
        })
    }

    vacancy(data) {
        return this.fetchJSON(`${BASE_URL}/vacancy`, {
            method: 'POST',
            body: data
        }, true).then(response => {
            return response.data
        })
    }

    feedbackRestaurant(data) {
        return this.fetchJSON(`${BASE_URL}/feedback-restaurant`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }

    feedbackDelivery(data) {
        return this.fetchJSON(`${BASE_URL}/feedback-delivery`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }

    giftCertificate(data) {
        return this.fetchJSON(`${BASE_URL}/gift-certificate`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }
}
