import {useEffect} from 'react'
import {YMInitializer} from 'react-yandex-metrika'

import {AppContext, AppContextValue} from "./store/context";
import {YM_COUNTER_ID} from "./const";

import Routes from './routing/routes'


export default function App() {
    const context = AppContextValue();

    const isMenusPage = () => {
        const currentPath = window.location.pathname;

        return currentPath.startsWith("/menu/") && currentPath !== "/menu";
    }

    document.body.style.overflow = (context.modals.address.state.open
    || context.modals.cart.state.open
    || context.modals.action.state.open
    || context.modals.personal.state.open
    || context.modals.restaurant.state.open
    || context.modals.nav.state.open
    || context.modals.product.state.open
    || context.modals.actionOnFuckingSinglePage.state.open
    || context.modals.operating.state.open
    || context.modals.giftCertificate.state.open
    || false) && !isMenusPage() ? "hidden" : "initial"

    useEffect(() => {
        context.processor.screen.init()
            .then(res => {
                context.cart.dispatch({type: 'setCart', payload: res.cart});
                context.common.dispatch({
                    type: 'setInitial', payload: {
                        categories: res.categories,
                        banners: res.banners,
                        restaurants: res.restaurants,
                        recommendations: res.recommendations,
                        actions: res.actions,
                        testimonials: res.testimonials,
                        hits: res.hits,
                        faq: res.faq,
                        operating: res.operating,
                    }
                });

                if (res.customer !== null) {
                    context.customer.dispatch({type: "set", payload: res.customer})
                }

                if (context.customer.state.isAuth !== true) {
                    context.modals.personal.dispatch({type: "open"})
                }

                if (res.operating !== true) {
                    context.modals.operating.dispatch({type: "open"})
                }
            })
            .then(() => context.common.dispatch({type: 'loaded'}))
    }, [])

    return <AppContext.Provider value={{context}}>
        <YMInitializer accounts={[YM_COUNTER_ID]} options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            ecommerce: 'dataLayer'
        }}/>
        <Routes/>
    </AppContext.Provider>
}
