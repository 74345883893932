import {Catalog} from "./catalog.api";
import {Address} from "./address.api";
import {Cart} from "./cart.api";
import {Screen} from "./screen.api";
import {Form} from "./form.api";
import {Personal} from "./personal.api";

export const Processor = {
    personal: new Personal(),
    catalog: new Catalog(),
    address: new Address(),
    screen: new Screen(),
    form: new Form(),
    cart: new Cart(),
};