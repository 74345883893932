import React, {useContext, useEffect, useState} from 'react';
import { YMaps, Placemark } from 'react-yandex-maps';
import { Map as YandexMap } from 'react-yandex-maps';
import {AddressSuggestions} from 'react-dadata';
import {BottomSheet} from "react-spring-bottom-sheet";

import {classNames} from "../../helper";
import {AppContext} from "../../store/context";
import {Map} from "../common/Map.js";

import address_error from '../../static/icons/catalog/address-error.svg'
import address_arrow from '../../static/icons/catalog/address-arrow-right.svg'
import cross from '../../static/icons/cross.svg'
import address_logo from '../../static/icons/catalog/address-logo.svg'

import styles from './Address.module.css'
import 'react-dadata/dist/react-dadata.css';

import {DADATA_TOKEN, KLADR_SPB, KLADR_LO, YANDEX_MAPS_API_KEY} from "../../const";
import {generatePath, useHistory} from "react-router-dom";
import {publicRoutes} from "../../routing";

export const ResponsiveAddressModal = () => {
    const { context } = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen = context.modals.address.state.open;

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen);
            }, 10);
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen);
            }, 400);
        }
    }, [isOpen]);

    const onDismiss = () => {
        context.modals.address.dispatch({ type: 'close' });
    };

    return context.settings.isMobile
        ? <BottomSheet
            className={"rbs-half"}
            blocking={false}
            header={<div className={styles.bsClearfix} />}
            open={open} onDismiss={onDismiss}
            snapPoints={({ maxHeight }) => maxHeight - 100}>
            <AddressModal />
        </BottomSheet>
        : <div className={classNames(styles.modal, (open ? styles.modalOpen : ''))}
               style={{ display: display ? "flex" : "none" }}>
            <div className={styles.cross} onClick={() => onDismiss()}>
                <img src={cross} alt="" />
            </div>
            <AddressModal />
        </div>;
};

export const AddressModal = () => {
    const { context } = useContext(AppContext);

    const [type, setType] = useState('delivery');
    const [selected, setSelected] = useState(null);

    const changeType = () => {
        setType(type === 'delivery' ? 'pickup' : 'delivery');
        setSelected(null);
    };

    useEffect(() => {
        if (context.modals.address.state.operation === "changeDeliveryType") {
            setType(context.cart.state.delivery.type === 'delivery' ? 'pickup' : 'delivery');
        }

        if (context.modals.address.state.operation === "changeAddress") {
            setType(context.cart.state.delivery.type);
        }
    }, [context.modals.address.state.operation]);

    const onSelect = (val) => {
        setSelected(val);
    };

    const points = () => {
        return context.common.state.restaurants.map(el => {
            return { name: el.name, lng: el.coordinates.longitude, lat: el.coordinates.latitude };
        });
    };

    return <div className={styles.address}>
        <div className={styles.addressSelector}>
            <div className={styles.title}>
                <div className={styles.titleText}>
                    {type === 'delivery' ? 'доставка' : 'самовывоз'}
                </div>
                <div className={styles.titleChangeType} onClick={() => {
                    changeType();
                }}>
                    {type === 'delivery' ? 'самовывоз -15%' : 'доставка'}
                </div>
            </div>
            {type === 'delivery' ? <Delivery onSelect={onSelect} /> : <Pickup onSelect={onSelect} />}
        </div>
        <div className={styles.map}>
            <YMaps query={{ apikey: YANDEX_MAPS_API_KEY }}>
                <YandexMap
                    defaultState={{
                        center: selected ? [selected.lat, selected.lng] : [59.8280011, 30.3378048],
                        zoom: selected ? 17 : 9,
                        controls: [],
                    }}
                    width="100%"
                    height="600px"
                >
                    {type === 'pickup' ? (
                        selected ? (
                            <Placemark
                                geometry={[selected.lat, selected.lng]}
                                properties={{
                                    iconContent: (
                                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <img src={address_logo} alt="" />
                                            <span>{selected.address}</span>
                                        </div>
                                    ),
                                }}
                                options={{
                                    iconLayout: 'default#imageWithContent',
                                    iconImageHref: address_logo,
                                    iconImageSize: [30, 30],
                                    iconImageOffset: [-15, -15],
                                }}
                            />
                        ) : (
                            points().map(el => (
                                <Placemark
                                    key={el.name}
                                    geometry={[el.lat, el.lng]}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: address_logo,
                                        iconImageSize: [30, 30],
                                        iconImageOffset: [-15, -15],
                                    }}
                                />
                            ))
                        )
                    ) : selected ? (
                        <Placemark
                            geometry={[selected.lat, selected.lng]}
                            options={{
                                iconLayout: 'default#image',
                                iconImageHref: address_logo,
                                iconImageSize: [30, 30],
                                iconImageOffset: [-15, -15],
                            }}
                        />
                    ) : null}
                </YandexMap>
            </YMaps>
        </div>
    </div>;
};

const Delivery = ({ onSelect }) => {
    const { context } = useContext(AppContext);
    const history = useHistory();

    const [value, setValue] = useState();
    const [error, setError] = useState();
    const [info, setInfo] = useState();

    const checkSuggestion = (val) => {
        setInfo(null);
        setError(null);

        if (val.data.house) {
            setValue(val);

            context.processor.address
                .checkAddress({ longitude: val.data.geo_lon, latitude: val.data.geo_lat })
                .then(res => {
                    setInfo(res);

                    const selected = { lng: parseFloat(val.data.geo_lon), lat: parseFloat(val.data.geo_lat) };
                    onSelect(selected);
                })
                .catch(err => setError(err.message));

            return;
        }

        setError("Пожалуйста, укажите дом");
    };

    const setAddress = () => {
        const address = {
            type: 'delivery',
            city: value.data.city,
            longitude: value.data.geo_lon,
            latitude: value.data.geo_lat,
            address: value.value,
            house: value.data.house,
            block: value.data.block,
            street: value.data.street_type === 'ул' ? value.data.street : `${value.data.street} ${value.data.street_type_full}`,
        };

        context.processor.address
            .setAddress(address)
            .then(res => {
                if (res.removed === true) {
                    context.toast.info('Некоторые блюда недоступны в выбранном ресторане и мы удалили их из корзины :(');
                }

                context.cart.dispatch({ type: "setCart", payload: res.cart });
            })
            .then(() => {
                if (context.modals.address.state.callback) {
                    context.modals.address.state.callback();
                }

                context.modals.address.dispatch({ type: "close" });
            })
            .catch(err => {
                setError(err.message);
            });
    };

    return <>
        <div className={styles.delivery}>
            <div className={styles.selector}>
                <div className={styles.selectorText}>Пожалуйста, укажите адрес доставки:</div>
                <div className={styles.selectorInput}>
                    <div className={styles.selectorInputHolder}>
                        <AddressSuggestions delay={500}
                                            count={5}
                                            containerClassName={styles.selectorAddressContainer}
                                            highlightClassName={styles.highlightAddressContainer}
                                            inputProps={{
                                                onInput: () => {
                                                    setInfo(null);
                                                },
                                                className: styles.selectorAddressInput
                                            }}
                                            minChars={4}
                                            token={DADATA_TOKEN}
                                            value={value}
                                            onChange={checkSuggestion}
                                            filterLocations={[{ kladr_id: KLADR_SPB }, { kladr_id: KLADR_LO }]}
                        />
                    </div>
                    {info ? <div className={styles.selectorButton} onClick={() => {
                        setAddress();
                    }}>
                        <span>Привезти сюда</span>
                        <img src={address_arrow} alt="" />
                    </div> : null}
                </div>
                {error ? <div className={styles.selectorError}>
                    <div><img src={address_error} alt="" /></div>
                    <div className={styles.selectorErrorText} dangerouslySetInnerHTML={{ __html: error }}></div>
                </div> : null}
            </div>
            {info ? <DeliveryResult info={info} /> : null}
        </div>
        {!info ? <div className={styles.conditions}
                      onClick={() => {
                          context.modals.address.dispatch({ type: 'close' });
                          context.modals.product.dispatch({ type: "close" });
                          context.modals.cart.dispatch({ type: "close" });
                          history.push(generatePath(publicRoutes.terms.path, {}));
                      }}>
            условия и зоны доставки
        </div> : null}
    </>;
};

const DeliveryResult = ({ info }) => {
    const { context } = useContext(AppContext);
    let color;
    let mobileText;
    switch (info?.deliveryPrice) {
        case 0:
            color = styles.resultSummaryIconGreen;
            mobileText = 'Зеленая зона';
            break;
        case 180:
            color = styles.resultSummaryIconYellow;
            mobileText = 'Желтая зона';
            break;
        case 250:
            color = styles.resultSummaryIconOrange;
            mobileText = 'Оранжевая зона';
            break;
    }

    return info ? <div className={styles.result}>
        <div className={styles.resultSummary}>
            <div className={styles.resultSummaryIcon}>
                <div className={color} />
            </div>
            <div className={styles.resultSummaryText}>
                {context.settings.isMobile ? mobileText : info.text}
            </div>
        </div>
        <div className={styles.resultDescription}>
            <div className={styles.resultPrice}>
                <div className={styles.resultLabel}>
                    {info.deliveryPrice === 0 ? 'БЕСПЛАТНО' : `ДОСТАВКА ${info.deliveryPrice}₽`}
                </div>
                <div className={styles.resultValues}>
                    {info.deliveryPrice !== 0 && !info.paidOnly ?
                        <div className={styles.resultValue}>
                            {`бесплатно при сумме заказа от ${info.deliveryFreePrice}₽`}
                        </div> : null}
                    <div className={styles.resultValue}>минимальная сумма заказа 1000₽</div>
                </div>
            </div>
            <div className={styles.resultTime}>
                <div className={styles.resultLabel}>ВРЕМЯ ДОСТАВКИ</div>
                <div className={styles.resultValues}>
                    <div className={styles.resultValue}>{info.deliveryTime}</div>
                </div>
            </div>
            <div className={styles.resultPayment}>
                <div className={styles.resultLabel}>типы оплаты</div>
                <div className={styles.resultValues}>
                    {info.cardOnly
                        ? <div className={styles.resultValue}>Онлайн оплата на сайте</div>
                        : <>
                            <div className={styles.resultValue}>Наличными курьеру</div>
                            <div className={styles.resultValue}>Онлайн оплата на сайте</div>
                            <div className={styles.resultValue}>Банковской картой курьеру (до 21:30)</div>
                        </>
                    }
                </div>
            </div>
        </div>
    </div> : null;
};

const Pickup = ({ onSelect }) => {
    const { context } = useContext(AppContext);

    const [rest, setRest] = useState(null);

    const selectRest = (el) => {
        const selected = { address: el.address, lng: el.coordinates.longitude, lat: el.coordinates.latitude };
        onSelect(selected);
        setRest(el);
    };

    const setAddress = () => {
        const address = {
            type: 'pickup',
            slug: rest.slug,
        };

        context.processor.address
            .setAddress(address)
            .then(res => {
                if (res.removed === true) {
                    context.toast.info('Некоторые блюда недоступны в выбранном ресторане и мы удалили их из корзины :(');
                }

                context.cart.dispatch({ type: "setCart", payload: res.cart });
            })
            .then(() => {
                if (context.modals.address.state.callback) {
                    context.modals.address.state.callback();
                }

                context.modals.address.dispatch({ type: "close" });
            })
            .catch(err => {
                context.toast.error(err.message);
            });
    };

    return <>
        <div className={styles.pickup}>
            <div className={styles.pickupText}>
                Вы можете забрать заказ самостоятельно из нашего ресторана со скидкой 15% при оплате наличными
            </div>
            <div className={styles.selectorInput}>
                {rest !== null ?
                    <>
                        <div className={styles.selectorInputHolder}>
                            <input type="text" readOnly={true} className={styles.selectorAddressInput}
                                   value={rest.address} />
                        </div>
                        <div className={styles.selectorButton} onClick={() => setAddress()}>
                            <span>Заберу отсюда</span>
                            <img src={address_arrow} alt="" />
                        </div>
                    </> : null}
            </div>
            <div className={styles.pickupSelector}>
                {rest == null
                    ? <div className={styles.pickupSelectorText}>Выберите ресторан:</div>
                    : <div className={styles.pickupSelectorInfo}>заказ будет готов в течении 30 минут</div>
                }
                <div className={styles.pickupSelectorItems}>
                    {context.common.state.restaurants.map(el => {
                        console.log(el)
                        if (el.isPickupEnabled === false) {
                            return <div className={classNames(styles.pickupSelectorItem, '')}>
                            <div className={styles.pickupSelectorIcon}>
                                    <img src={address_logo} alt=""/>
                                </div>
                                <div className={styles.pickupSelectorItemText}>{el.name} (Временно недоступно)</div>
                            </div>
                        }
                        return <div onClick={() => selectRest(el)}
                                    className={classNames(styles.pickupSelectorItem, rest && rest.slug === el.slug && el.isPickupEnabled === true ? styles.active : '')}>
                            <div className={styles.pickupSelectorIcon}>
                                <img src={address_logo} alt=""/>
                            </div>
                            <div className={styles.pickupSelectorItemText}>{el.name}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    </>
}
