import React, {useContext, useState, useEffect} from "react";
import {generatePath, Link} from "react-router-dom";


import {AppContext} from "../../store/context";
import {LoaderFullsize} from "../../components/common/Loader";
import {classNames} from "../../helper";
import {RedButton, WhiteButton} from "../../components/common/Buttons";
import {publicRoutes} from "../../routing";

import logoRed from '../../static/icons/logo_bullet_red.svg'
import logo from '../../static/icons/logo_bullet.svg'
import back from "../../static/icons/conditions/back.svg";
import arrowdown from '../../static/icons/restaurant/shevron-down.svg';
import arrowup from '../../static/icons/restaurant/shevron-up.svg';
import certificateImg from '../../static/icons/menu-icon-gift.svg';

import styles from "./Restaurants.module.css";
import {STATIC_HOST_GALLERY} from "../../const";

export default class Restaurants extends React.Component {
    constructor() {
        super()

        this.state = {
            selected: null,
            open: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.setState({selected: this.context.context.common.state.restaurants[0]})
    }

    render() {
        return this.state.selected ? <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <h1 className={styles.titleText}>рестораны корейской культуры</h1>
            </div>
            {this.context.context.settings.isMobile ?
                <div className={styles.selector}>
                    <div className={styles.selectorItem} onClick={() => this.setState({open: true})}>
                        <SelectorItem el={this.state.selected} selected={this.state.selected} onClick={() => []}/>
                        <img src={arrowdown} alt=""/>
                    </div>
                    <div className={classNames(styles.selectorItems, this.state.open ? styles.open : "")}>
                        <img onClick={() => this.setState({open: false})} src={arrowup} alt=""/>
                        {this.context.context.common.state.restaurants.map(el => {
                            return <SelectorItem el={el}
                                                 selected={this.state.selected}
                                                 onClick={(data) => this.setState({open: false, selected: data})}/>
                        })}
                    </div>
                </div> :
                <div className={styles.selector}>
                    <div className={styles.selectorClearfix}/>
                    <div className={styles.selectorItems}>
                        {this.context.context.common.state.restaurants.map(el => {
                            return <SelectorItem el={el}
                                                 selected={this.state.selected}
                                                 onClick={(data) => this.setState({open: false, selected: data})}/>
                        })}
                    </div>
                </div>}
            {this.context.context.settings.isMobile
                ? <div className={styles.inner}>
                    <div className={styles.restTitle}>{this.state.selected?.name}</div>
                    <Tags selected={this.state.selected}/>
                    <div className={styles.restOperating}>{this.state.selected?.operating}</div>
                    <About selected={this.state.selected}/>
                    <Gallery selected={this.state.selected}/>
                    <div className={styles.mobileButtons}>
                        <Link className={styles.restLink}
                              to={generatePath(publicRoutes.restaurant.path, {slug: this.state.selected?.slug})}>
                            подробнее о ресторане
                        </Link>
                        <Buttons selected={this.state.selected}/>
                    </div>
                </div> : <div className={styles.inner}>
                    <Gallery selected={this.state.selected}/>
                    <div className={styles.details}>
                        <div className={styles.restTitle}>{this.state.selected.name}</div>
                        <Tags selected={this.state.selected}/>
                        <About selected={this.state.selected}/>
                        <Buttons selected={this.state.selected}/>
                    </div>
                </div>}
        </div> : <LoaderFullsize/>
    }
}

const Gallery = ({selected}) => {
    const [items, setItems] = useState([])
    const [current, setCurrent] = useState(null)

    useEffect(() => {
        if (selected && selected.gallery.images.length > 0) {
            setCurrent(selected.gallery.images[0])
            setItems(selected.gallery.images.slice(selected.gallery.images.length - 4))
        } else {
            setCurrent(null)
        }
    }, [selected])
    const changeSlide = (el) => {
        const it = items
        const i = it.findIndex((e) => e === el)

        it[i] = current

        setItems(it)
        setCurrent(el)
    }

    return current ? <div className={styles.gallery}>
        <div className={styles.galleryImages}>
            {items.map(el => {
                return <div className={styles.galleryImage}>
                    <img src={STATIC_HOST_GALLERY + el.source} alt={el.alt} onClick={() => {
                        changeSlide(el)
                    }}/>
                </div>
            })}
        </div>
        <div className={styles.galleryActive}>
            <img src={STATIC_HOST_GALLERY + current.source || ''} alt={current.alt}/>
        </div>
    </div> : null
}

const Tags = ({selected}) => {
    let tags = []
    if (selected.tags && selected.tags != "") {
        tags = selected.tags.split(",")
    }

    return tags.length > 0 ?
        <div className={styles.restTags}>
            {tags?.map(el => {
                return <div className={styles.restTag}>{el.trim()}</div>
            })}
        </div> : null
}
const About = ({selected}) => {
    return <div className={styles.restAbout}>
        <a href={selected.map}  target={"_blank"} className={styles.restAboutSect} >
            <div className={styles.restAboutLabel}>Открыть на Google Maps</div>
            <div className={styles.restAboutValue}>{selected.address}</div>
            <div className={styles.restAboutSub}>{selected.operating}</div>
        </a>
        <div className={styles.restAboutSect}>
            <div className={styles.restAboutLabel}>Позвонить</div>
            <a href={`tel:${selected.phone}`} className={styles.restAboutValue}>{selected.phone}</a>
            <div className={styles.restAboutSub}>
                <Link to={generatePath(publicRoutes.restaurant.path, {slug: selected.slug})}>
                    подробнее о ресторане
                </Link>
            </div>
        </div>
    </div>
}

const Buttons = ({selected}) => {
    const {context} = useContext(AppContext)
    return <>
        <div className={styles.restButtons}>
            <RedButton
                onClick={() => context.modals.restaurant.dispatch({type: "show", payload: selected})}
                text={'ЗАКАЗАТЬ СТОЛИК'}/>
            <WhiteButton link={"/menu"} text={'меню ресторана'}/>
        </div>
        <div className={styles.certificateButton}
             onClick={() => {
                 context.modals.giftCertificate.dispatch({type: 'open'});
             }}
        >
            <img src={certificateImg} alt={''} />
            <span>Подарочный сертификат</span>
        </div>
    </>
}

const SelectorItem = ({el, selected, onClick}) => {
    return <div className={styles.selectorItem}
                onClick={() => onClick(el)}>
        <div className={styles.selectorIcon}>
            <img src={selected.slug === el.slug ? logoRed : logo} alt=""/>
        </div>
        <div
            className={classNames(styles.selectorTitle, selected.slug === el.slug ? styles.active : '')}>{el.name}</div>
    </div>
}

Restaurants.contextType = AppContext;