import React from "react";
import {Link} from "react-router-dom";
import styles from "./KuksuMania.module.css";

import {AppContext} from "../../store/context";

import imgIntroMobile from "../../static/images/kuksumania/intro-mobile.jpg";
import imgIntroTablet from "../../static/images/kuksumania/intro-tablet.jpg";
import imgIntroDesktop from "../../static/images/kuksumania/intro-desktop.jpg";
import imgPromotionLogo1 from "../../static/images/kuksumania/promotion-logo-1.png";
import imgPromotionLogo2 from "../../static/images/kuksumania/promotion-logo-2.png";
import imgPromotionLogo3 from "../../static/images/kuksumania/promotion-logo-3.png";
import imgLotteryKitfort from "../../static/images/kuksumania/lottery-kitfort.png";
import imgLotteryBibigo from "../../static/images/kuksumania/lottery-bibigo.png";
import imgLotteryHah from "../../static/images/kuksumania/lottery-hah.png";
import imgLotteryIdolcut from "../../static/images/kuksumania/lottery-idolcut.png";
import imgLotteryKorea from "../../static/images/kuksumania/lottery-korea.jpg";
import imgLotteryNeva from "../../static/images/kuksumania/lottery-neva.png";
import imgLotterySeno from "../../static/images/kuksumania/lottery-seno.png";
import imgStepsCard from "../../static/images/kuksumania/steps-card.jpg";
import imgFestivalDesktop from "../../static/images/kuksumania/festival-desktop.png";
import imgFestivalMobile from "../../static/images/kuksumania/festival-mobile.png";
import imgBattleDesktop from "../../static/images/kuksumania/battle-desktop.jpg";
import imgBattleTablet from "../../static/images/kuksumania/battle-tablet.jpg";
import imgBattleMobile from "../../static/images/kuksumania/battle-mobile.jpg";
import imgProgramDesktop1 from "../../static/images/kuksumania/program-img-1-desktop.jpg";
import imgProgramTablet1 from "../../static/images/kuksumania/program-img-1-tablet.jpg";
import imgProgramMobile1 from "../../static/images/kuksumania/program-img-1-mobile.jpg";
import imgProgramDesktop2 from "../../static/images/kuksumania/program-img-2-desktop.jpg";
import imgProgramTablet2 from "../../static/images/kuksumania/program-img-2-tablet.jpg";
import imgProgramMobile2 from "../../static/images/kuksumania/program-img-2-mobile.jpg";
import imgProgramDesktop3 from "../../static/images/kuksumania/program-img-3-desktop.jpg";
import imgProgramTablet3 from "../../static/images/kuksumania/program-img-3-tablet.jpg";
import imgProgramMobile3 from "../../static/images/kuksumania/program-img-3-mobile.jpg";
import imgProgramDesktop4 from "../../static/images/kuksumania/program-img-4-desktop.jpg";
import imgProgramTablet4 from "../../static/images/kuksumania/program-img-4-tablet.jpg";
import imgProgramMobile4 from "../../static/images/kuksumania/program-img-4-mobile.jpg";
import imgStickersShopper from "../../static/images/kuksumania/stickers-shopper.png";
import imgStickersBottle from "../../static/images/kuksumania/stickers-bottle.png";

export default class KuksuMania extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false,
            restaurant: null,
        }

        this.seoTitle = 'ВАШ НОВЫЙ ЛЮБИМЫЙ СУП';
        this.seoDescription = 'Куксу - корейское национальное блюдо с ярким вкусом и ароматом. Наша команда приготовила для всех любителей куксу подарки, поэтому есть его будет вдвойне вкуснее!';
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.common.state.seo.title = this.seoTitle;
        this.context.context.common.state.seo.description =  this.seoDescription;
    }

    componentDidUpdate() {
        this.context.context.common.state.seo.title = this.seoTitle;
        this.context.context.common.state.seo.description =  this.seoDescription;
    }

    render() {
        return <>
             <main className={styles.main}>
                <div className={styles.container} >
                    {/* intro */}
                    <section className={`${styles.intro} ${styles.pageSectionLine}`}>
                        <div className={styles.introHeader}>
                            <span className={styles.pageTitleAlt}>좋아하는 수프</span>
                            <h1 className={styles.pageTitle}>Ваш новый любимый суп</h1>
                        </div>
                        <div className={styles.introContent}>
                            <div className={styles.introImgContainer}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={imgIntroMobile}
                                        width="360"
                                        height="233"
                                    /> */}
                                    <source
                                        media="(max-width: 1025px)"
                                        srcSet={imgIntroTablet}
                                        width="688"
                                        height="422"
                                    />
                                    <img
                                        src={imgIntroDesktop}
                                        width="633"
                                        height="422"
                                        alt="Куксу"
                                        className={styles.introImg}
                                    />
                                </picture>
                            </div>
                            <div className={styles.introTextContentTop}>
                                <p className={styles.introTextTopUppercase}>Идеальный баланс острого, кислого и сладкого</p>
                                <p className={styles.introText}>Куксу — уникальное блюдо, которое отражает богатое наследие корейской культуры. Каждый ингредиент отражает свои особенности: сочетание холодного бульона, тонкой пшеничная лапши, свежих овощей, обжаренного мяса — идеальный баланс острого, кислого и сладкого. Насыщенность вкусов и ароматов, покорит сердца всех гурманов.</p>
                            </div>
                            <div className={styles.introTextContentBottomWrapper}>
                                <p className={styles.introTextContentBottom}>
                                    <span className={`${styles.introTextRed} ${styles.introTextUppercase}`}>Более миллиона</span> <span className={styles.introTextUppercase}>порций куксу</span> мы приготовили в стенах наших ресторанов с 2013&nbsp;г.
                                </p>
                            </div>
                        </div>
                    </section>

                    {/* promotion */}
                    <section className={`${styles.promotion} ${styles.pageSectionLine}`}>
                        <div className={styles.promotionTextContent}>
                            <div className={styles.promotionHeader}>
                                <span className={styles.pageTitleAlt}>마니아</span>
                                <h2 className={styles.pageTitle}>куксу-мания</h2>
                            </div>
                            <p className={styles.promotionText}>Куксу-мания — акция с 15.07 по 25.08, которая проходит во всех наших ресторанах. Наслаждайтесь ярким вкусом, собирайте наклейки, участвуйте в розыгрыше!</p>
                            <p className={styles.promotionTextColor}>Мы ждем вас на&nbsp;фестивале&nbsp;26.08.23!</p>
                        </div>
                        <div className={styles.promotionLogotypeContent}>
                            <img
                                src={imgPromotionLogo1}
                                width="215"
                                height="83"
                                alt="Кореана ресторан корейской культуры"
                                className={styles.promotionLogo1}
                            />
                            <img
                                src={imgPromotionLogo2}
                                width="223"
                                height="71"
                                alt="Кореана гармония вкуса и пользы"
                                className={styles.promotionLogo2}
                            />
                            <img
                                src={imgPromotionLogo3}
                                width="220"
                                height="42"
                                alt="Kimchi"
                                className={styles.promotionLogo3}
                            />
                        </div>
                    </section>

                    {/* lottery */}
                    <section className={`${styles.lottery} ${styles.pageSectionLine}`}>
                        <div className={styles.lotteryTextContent}>
                            <div className={styles.lotteryHeader}>
                                <span className={styles.pageTitleAlt}>래플</span>
                                <h2 className={styles.pageTitle}>Розыгрыш</h2>
                            </div>
                            <p className={styles.lotteryTextColor}>испытайте удачу, участвуя в&nbsp;розыгрыше!</p>
                            <ol className={styles.lotteryListPrizes}>
                                <li className={`${styles.lotteryListPrizesItem} ${styles.lotteryListPrizesItemColor}`}>1 место — <span>Кофемашина</span></li>
                                <li className={styles.lotteryListPrizesItem}>2 место — <span>Робот-пылесос</span></li>
                                <li className={styles.lotteryListPrizesItem}>3 место — <span>Электрогриль</span></li>
                            </ol>
                            <p className={styles.lotteryText}>10 maxi, 20 middle, 30 mini боксов от партнеров:</p>
                            <ul className={`${styles.lotteryList} ${styles.pageListStyleLine}`}>
                                <li className={styles.pageListStyleLineItem}>Сертификаты на посещение одного из наших ресторанов</li>
                                <li className={styles.pageListStyleLineItem}>Сертификаты на изучение корейского языка от корейского центра НАН</li>
                                <li className={styles.pageListStyleLineItem}>Сертификаты на обучение К-ПОП танцам</li>
                            </ul>
                            <p className={styles.lotteryText}>Брендированные футболки</p>
                            <p className={styles.lotteryText}>Корейские продукты</p>
                            <p className={styles.lotteryText}>Настольные часы</p>
                            <p className={styles.lotteryText}>Ночник-колонка</p>
                            <p className={styles.lotteryText}>Чайник</p>
                        </div>
                        <div className={styles.lotteryLogoContainer}>
                            <div className={styles.lotteryLogoContent}>
                                <div className={styles.lotteryLogoWrapper}>
                                    <a href="https://kitfort.ru/" target="_blank" rel="noreferrer noopener">
                                        <img
                                            src={imgLotteryKitfort}
                                            width="1160"
                                            height="463"
                                            alt="KITFORT"
                                            className={styles.lotteryLogo}
                                        />
                                    </a>
                                </div>
                                <div className={styles.lotteryLogoTextContainer}>
                                    <p className={styles.lotteryLogoText}>Kitfort– отечественная компания-производитель бытовой техники для дома, кухни, красоты и здоровья.</p>
                                    <p className={styles.lotteryLogoText}>Главный девиз - «Всегда что-то новенькое!».</p>
                                    <p className={styles.lotteryLogoText}>Каждый найдет для себя товар качественный, который впишется в любой интерьер.</p>
                                    <p className={styles.lotteryLogoText}>В своей официальной группе в <a href="https://vk.com/kitfort" target="_blank" rel="noreferrer noopener">ВК Kitfort</a> постоянно устраивает розыгрыши, поэтому подписывайтесь и участвуйте.</p>
                                </div>
                            </div>
                            <div className={styles.lotteryOtherLogoContent}>
                                <a href="https://vk.com/centernan" target="_blank" rel="noreferrer noopener" className={styles.lotteryLogoHah}>
                                    <img
                                        src={imgLotteryHah}
                                        width="713"
                                        height="472"
                                        alt="hah"
                                    />
                                </a>
                                <a href="https://idol-cut.ru/" target="_blank" rel="noreferrer noopener" className={styles.lotteryLogoIdolCut}>
                                    <img
                                        src={imgLotteryIdolcut}
                                        width="1775"
                                        height="427"
                                        alt="idol-cut"
                                    />
                                </a>
                                <a href="https://www.cjraviollo.ru/" target="_blank" rel="noreferrer noopener" className={styles.lotteryLogoBibigo}>
                                    <img
                                        src={imgLotteryBibigo}
                                        width="894"
                                        height="1023"
                                        alt="Bibigo"
                                    />
                                </a>
                                {/* <a href="https://vk.com/neva.trip" target="_blank" rel="noreferrer noopener" className={styles.lotteryLogoNeva}>
                                    <img
                                        src={imgLotteryNeva}
                                        width="969"
                                        height="185"
                                        alt="Neva trip"
                                    />
                                </a> */}
                                <a href="https://vk.com/koreamarket_spb" target="_blank" rel="noreferrer noopener" className={styles.lotteryLogoKorea}>
                                    <img
                                        src={imgLotteryKorea}
                                        width="1024"
                                        height="1024"
                                        alt="Korea market"
                                    />
                                </a>
                                <a href="https://seno.spb.ru/" target="_blank" rel="noreferrer noopener" className={styles.lotteryLogoSeno}>
                                    <img
                                        src={imgLotterySeno}
                                        width="886"
                                        height="886"
                                        alt="Seno"
                                    />
                                </a>
                            </div>
                        </div>

                    </section>

                    {/* stickers */}
                    <section className={styles.stickers}>
                        <div className={styles.stickersContainer}>
                            <ul className={styles.stickersSteps}>
                                <li className={styles.stickersStepsItem}>
                                    <p className={styles.stickersStepsItemContent}>
                                        <span className={styles.stickersStepsItemTextColor}>Заказывайте </span>
                                        <span className={styles.stickersStepsItemText}>куксу</span>
                                    </p>
                                </li>
                                <li className={styles.stickersStepsItem}>
                                    <p className={styles.stickersStepsItemContent}>
                                        <span className={styles.stickersStepsItemTextColor}>Собирайте </span>
                                        <span className={styles.stickersStepsItemText}>наклейки</span>
                                    </p>
                                </li>
                                <li className={styles.stickersStepsItem}>
                                    <p className={styles.stickersStepsItemContent}>
                                        <span className={styles.stickersStepsItemTextColor}>Выигрывайте </span>
                                        <span className={styles.stickersStepsItemText}>подарки *</span>
                                    </p>
                                    <p className={styles.stickersStepsItemContent}>
                                        <span className={styles.stickersStepsItemTextColor}>Участвуйте </span>
                                        <span className={styles.stickersStepsItemText}>в розыгрыше</span>
                                    </p>
                                </li>
                            </ul>
                            <div className={styles.stickersPrizes}>
                                <p className={`${styles.stickersPrizesItem}`}>
                                    <span className={styles.stickersPrizesItemText}>3 наклейки </span>
                                    <span className={styles.stickersPrizesItemTextColor}>Шоппер</span>
                                    <img
                                        src={imgStickersShopper}
                                        alt="Шоппер"
                                        className={styles.stickersPrizesImg}
                                    />
                                </p>
                                <p className={`${styles.stickersPrizesItem}`}>
                                    <span className={styles.stickersPrizesItemText}>6 наклеек </span>
                                    <span className={styles.stickersPrizesItemTextColor}>Бутылка для воды</span>
                                    <img
                                        src={imgStickersBottle}
                                        alt="Бутылка"
                                        className={styles.stickersPrizesImg}
                                    />
                                </p>
                            </div>
                            <p className={styles.stickersTextBottom}>*Наклейки можно обменять на подарки 26.08 на фестивале<br /> Куксу-мания, пространство SENO</p>
                        </div>
                    </section>

                    {/* steps */}
                    <section className={`${styles.steps} ${styles.pageSectionLine}`}>
                        <Link to={'/delivery/soups'} className={styles.stepsCard}>
                            <img
                                src={imgStepsCard}
                                width="330"
                                height="199"
                                alt="Кук-су"
                                className={styles.stepsCardImg}
                            />
                            <div className={styles.stepsCardTextContent}>
                                <h3 className={styles.stepsCardTitle}>Куксу</h3>
                                <p className={styles.stepsCardText}>Традиционный корейский суп с тонкой пшеничной лапшой, говядиной и овощами.</p>
                                <span className={styles.stepsCardBtn}>Хочу куксу</span>
                            </div>
                        </Link>
                        <div className={styles.stepsTextContent}>
                            <ol className={styles.stepsList}>
                                <li className={styles.stepsListItem}>До 25 августа заказывайте куксу на доставку или в ресторанах Кореана, <a href="https://koreanalight.ru/menu/sypi" target="_blank" rel="noreferrer noopener">Кореана Light</a>, <a href="https://vk.com/kimchitogo" target="_blank" rel="noreferrer noopener">Kimchi to go</a> и&nbsp;собирайте наклейки</li>
                                <li className={styles.stepsListItem}>Обменивайте наклейки на призы</li>
                                <li className={styles.stepsListItem}>Участвуйте в розыгрыше</li>
                                <li className={styles.stepsListItem}>Приходите на наш грандиозный корейский фестиваль этим летом</li>
                            </ol>
                            <p className={styles.stepsTextBottom}>Да начнется <span className={styles.stepsTextColor}>куксу-мания!</span></p>
                        </div>
                    </section>

                    {/* festival */}
                    <section className={`${styles.festival} ${styles.pageSectionLine}`}>
                        <div className={styles.festivalHeader}>
                            <div>
                                <span className={styles.pageTitleAlt}>축제</span>
                                <h2 className={styles.pageTitle}>Фестиваль корейских впечатлений</h2>
                            </div>
                        </div>
                        <div className={styles.festivalContent}>
                            <div className={styles.festivalImgContainer}>
                                <picture>
                                    <source
                                        media="(max-width: 768px)"
                                        srcSet={imgFestivalMobile}
                                        width="450"
                                        height="406"
                                    />
                                    <img
                                        src={imgFestivalDesktop}
                                        width="477"
                                        height="490"
                                        alt="Фестиваль"
                                        className={styles.festivalImg}
                                    />
                                </picture>
                            </div>
                            <div className={styles.festivalTextContent}>
                                <p className={styles.festivalText}><b>Где:</b> площадка SENO (Гороховая, 49)</p>
                                <p className={styles.festivalText}><b>Время и дата:</b> 26.08 с 13:00 до 18:00</p>
                                <a href="https://seno.spb.ru/" target="_blank" rel="noreferrer noopener">
                                    <img
                                        src={imgLotterySeno}
                                        width="886"
                                        height="886"
                                        alt="Seno"
                                        className={styles.festivalTextContentLogo}
                                    />
                                </a>
                                <p className={styles.festivalTextColor}>Погрузитесь в мир корейской культуры</p>
                                <p className={styles.festivalListTitle}>Вас ждет:</p>
                                <ul className={`${styles.festivalList} ${styles.pageListStyleLine}`}>
                                    <li className={styles.pageListStyleLineItem}>Куксу-баттл</li>
                                    <li className={styles.pageListStyleLineItem}>Розыгрыш</li>
                                    <li className={styles.pageListStyleLineItem}>Мастер-классы от корейского центра НАН</li>
                                    <li className={styles.pageListStyleLineItem}>Фотозона</li>
                                    <li className={styles.pageListStyleLineItem}>Корейский рынок</li>
                                    <li className={styles.pageListStyleLineItem}>К-поп танцы и многое другое</li>
                                </ul>
                                <p className={styles.festivalTextBottom}>Наш <span className={styles.festivalTextBottomColor}>первый</span> масштабный корейский фестиваль в&nbsp;Санкт&#8209;Петербурге</p>
                            </div>
                        </div>
                    </section>

                    {/* battle */}
                    <section className={`${styles.battle} ${styles.pageSectionLine}`}>
                        <div className={styles.battleContent}>
                            <div className={styles.battleHeader}>
                                <span className={styles.pageTitleAlt}>국수 배틀</span>
                                <h2 className={styles.pageTitle}>Куксу-баттл</h2>
                            </div>
                            <p className={styles.battleTextColor}>Каждый может поучаствовать в куксу-баттле</p>
                            <ol className={styles.battlePrizes}>
                                <li className={`${styles.battlePrizesItem} ${styles.battlePrizesItemColor}`}>1 место — <span>30&nbsp;000&nbsp;</span>рублей</li>
                                <li className={styles.battlePrizesItem}>2 место — 20 000 рублей</li>
                                <li className={styles.battlePrizesItem}>3 место — 10 000 рублей</li>
                            </ol>
                            <ol className={styles.battleList}>
                                <li className={styles.battleListItem}>1 этап — квалификация (все желающие едят куксу на скорость)</li>
                                <li className={styles.battleListItem}>2 этап — 10 сильнейших участников проходят в финал и борются за 3 места</li>
                            </ol>
                            <p className={styles.battleTextBottom}>*Участие в баттле стоит 300 рублей</p>
                            <p className={styles.battleTextBottom}>1 этап – квалификация с 13:00 до 15:00 (на фестивале все желающие едят куксу на скорость)</p>
                            <p className={styles.battleTextBottom}>2 этап – 10 сильнейших участников проходят в финал и борются за 3 места (начало в 16:00)</p>
                        </div>
                        <div className={styles.battleImgContainer}>
                            <picture>
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={imgBattleMobile}
                                    width="320"
                                    height="442"
                                />
                                <source
                                    media="(max-width: 1025px)"
                                    srcSet={imgBattleTablet}
                                    width="704"
                                    height="690"
                                />
                                <img
                                    src={imgBattleDesktop}
                                    width="418"
                                    height="668"
                                    alt="Фестиваль"
                                    className={styles.battleImg}
                                />
                            </picture>
                        </div>
                    </section>

                    {/* program */}
                    <section className={styles.program}>
                        <div className={styles.programHeader}>
                            <span className={styles.pageTitleAlt}>축제</span>
                            <h2 className={styles.pageTitle}>Программа фестиваля</h2>
                        </div>
                        <div className={styles.programContent}>
                            <div className={styles.programList}>
                                <div className={`${styles.programListItem} ${styles.programListItemLine}`}>
                                    <div className={styles.programListItemTextContent}>
                                        <p className={styles.programListStyleTitle}>В течение всего дня для вас будут работать:</p>
                                        <ul className={`${styles.programListStyle} ${styles.pageListStyleLine}`}>
                                            <li className={styles.pageListStyleLineItem}>Фотозона с ханбоками и другой национальной атрибутикой Кореи.<br /> Ростовые куклы Idol;</li>
                                            <li className={styles.pageListStyleLineItem}>Колесо фортуны и корейские национальные игры; </li>
                                            <li className={styles.pageListStyleLineItem}>Корейский рынок, чтобы почувствовать атмосферу Сеула;</li>
                                            <li className={styles.pageListStyleLineItem}>Активности от партнеров и многое другое.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={`${styles.programListItem} ${styles.programListItemTitleTime}`}>
                                    <div className={styles.programListItemTextContent}>
                                        <p className={styles.programListStyleTitle}>Расписание мероприятий:</p>
                                    </div>
                                </div>
                                <div className={styles.programListItem}>
                                    <span className={styles.programListItemTime}>13:00</span>
                                    <div className={styles.programListItemTextContent}>
                                        <p>Торжественное открытие фестиваля. Номер&nbsp;с&nbsp;барабанщиками </p>
                                    </div>
                                </div>
                                <div className={styles.programListItem}>
                                    <span className={styles.programListItemTime}>13:30</span>
                                    <div className={styles.programListItemTextContent}>
                                        <p>Public talk</p>
                                    </div>
                                </div>
                                <div className={styles.programListItem}>
                                    <span className={styles.programListItemTime}>14:00</span>
                                    <div className={styles.programListItemTextContent}>
                                        <p>Мастер-класс от группы MILLENNIUM&nbsp;DANCE&nbsp;STUDIO</p>
                                    </div>
                                </div>
                                <div className={styles.programListItem}>
                                    <span className={styles.programListItemTime}>15:00</span>
                                    <div className={styles.programListItemTextContent}>
                                        <p>Квиз от ведущего</p>
                                    </div>
                                </div>
                                <div className={styles.programListItem}>
                                    <span className={styles.programListItemTime}>16:00</span>
                                    <div className={styles.programListItemTextContent}>
                                        <p>Куксу-баттл. Финальная битва</p>
                                    </div>
                                </div>
                                <div className={styles.programListItem}>
                                    <span className={styles.programListItemTime}>16:30</span>
                                    <div className={styles.programListItemTextContent}>
                                        <p>Розыгрыш призов от партнеров</p>
                                    </div>
                                </div>
                                <div className={styles.programListItem}>
                                    <span className={styles.programListItemTime}>17:00</span>
                                    <div className={styles.programListItemTextContent}>
                                        <p>Церемония закрытия фестиваля</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.programImgContainer} ${styles.programImgContainer1}`}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={imgProgramMobile1}
                                        width="320"
                                        height="261"
                                    /> */}
                                    <source
                                        media="(max-width: 1025px)"
                                        srcSet={imgProgramTablet1}
                                        width="704"
                                        height="575"
                                    />
                                    <img
                                        src={imgProgramDesktop1}
                                        width="401"
                                        height="575"
                                        alt=""
                                    />
                                </picture>
                            </div>
                            <div className={`${styles.programImgContainer} ${styles.programImgContainer2}`}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={imgProgramMobile2}
                                        width="320"
                                        height="213"
                                    /> */}
                                    <source
                                        media="(max-width: 1025px)"
                                        srcSet={imgProgramTablet2}
                                        width="704"
                                        height="469"
                                    />
                                    <img
                                        src={imgProgramDesktop2}
                                        width="401"
                                        height="436"
                                        alt=""
                                    />
                                </picture>
                            </div>
                            <div className={`${styles.programImgContainer} ${styles.programImgContainer3}`}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={imgProgramMobile3}
                                        width="320"
                                        height="163"
                                    /> */}
                                    <source
                                        media="(max-width: 1025px)"
                                        srcSet={imgProgramTablet3}
                                        width="704"
                                        height="358"
                                    />
                                    <img
                                        src={imgProgramDesktop3}
                                        width="609"
                                        height="310"
                                        alt=""
                                    />
                                </picture>
                            </div>
                            <div className={`${styles.programImgContainer} ${styles.programImgContainer4}`}>
                                <picture>
                                    {/* <source
                                        media="(max-width: 768px)"
                                        srcSet={imgProgramMobile4}
                                        width="320"
                                        height="233"
                                    /> */}
                                    <source
                                        media="(max-width: 1025px)"
                                        srcSet={imgProgramTablet4}
                                        width="704"
                                        height="513"
                                    />
                                    <img
                                        src={imgProgramDesktop4}
                                        width="347"
                                        height="254"
                                        alt=""
                                    />
                                </picture>
                            </div>
                        </div>
                    </section>
                </div>
            </main>

            <footer className={styles.footer}>
                <div className={styles.footerContainer}>
                    <div className={`${styles.footerColumn} ${styles.footerColumnSocial}`}>
                        <a href="/doc/rules-kuksu-mania.pdf" target="_blank"  rel="noreferrer noopener" className={styles.footerSocialTitle}>Правила акции</a>
                        <ul className={styles.footerSocialList}>
                            <li className={styles.footerSocialItem}>
                                <a
                                    className={styles.footerSocialLink}
                                    href="https://vk.com/kimchitogo"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    kimchitogo
                                </a>
                            </li>
                            <li className={styles.footerSocialItem}>
                                <a
                                    className={styles.footerSocialLink}
                                    href="https://vk.com/koreana.rest"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    koreana.rest
                                </a>
                            </li>
                            <li className={styles.footerSocialItem}>
                                <a
                                    className={styles.footerSocialLink}
                                    href="https://vk.com/koreanalight"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    koreanalight
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={`${styles.footerColumn} ${styles.footerColumnCopyright}`}>
                        <Link to={'/confidential'} className={styles.footerPolicyLink}>Политика конфиденциальности</Link>
                        <p className={styles.footerCopyright}>© 2023, Кореана</p>
                    </div>
                </div>
            </footer>
        </>
    }
}

KuksuMania.contextType = AppContext;
