import React, {useContext} from 'react';
import {Link} from 'react-router-dom';

import {AppContext} from "../../store/context";
import {publicRoutes} from "../../routing";

import vk from '../../static/icons/vk.svg';
import logo from '../../static/icons/footer-logo.svg';
import logoPay from '../../static/icons/logo-pay.svg';

import styles from './Footer.module.css';

export const Footer = () => {
    const {context} = useContext(AppContext)

    return context.settings.isMobile
        ? <MobileFooter/>
        : <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.colOne}>
                    <div className={styles.menu}>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.about.path}>О нас</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.blog.path}>Блог</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.vacancies.path}>Вакансии</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.terms.path}>Условия доставки</Link>
                        </div>
                    </div>
                    <div className={styles.social}>
                        <a className={styles.socialItem} href="https://vk.com/koreana.rest" target="_blank" rel="noreferrer">
                            <div className={styles.socialItemIcon}>
                                <img src={vk} alt=""/>
                            </div>
                            <div className={styles.socialItemText}>koreana.rest</div>
                        </a>
                    </div>
                </div>
                <div className={styles.colTwo}>
                    <div className={styles.menu}>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.restaurants.path}>Рестораны</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.delivery.path}>Доставка</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.restaurants.path}>Контакты</Link>
                        </div>
                    </div>
                    <div className={styles.disclamer}>
                        <div className={styles.disclamerItem}>
                            <a href="/confidential" target={"_blank"}>Политика конфиденциальности</a>
                        </div>
                        <div className={styles.disclamerItem}>© 2023, Кореана</div>
                        <div className={styles.disclamerItem}>Design by Alina Yun</div>
                    </div>
                </div>
            </div>
        </div>
}

const MobileFooter = () => {

    return <div className={styles.container}>
        <div className={styles.inner}>
            <div className={styles.columns}>
                <div className={styles.colOne}>
                    <div className={styles.menu}>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.about.path}>О нас</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.blog.path}>Блог</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.vacancies.path}>Вакансии</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.terms.path}>Условия доставки</Link>
                        </div>
                    </div>
                    <div className={styles.social}>
                        <a className={styles.socialItem} href="https://vk.com/koreana.rest" target="_blank" rel="noreferrer">
                            <div className={styles.socialItemIcon}>
                                <img src={vk} alt=""/>
                            </div>
                            <div className={styles.socialItemText}>koreana.rest</div>
                        </a>
                    </div>
                </div>
                <div className={styles.colTwo}>
                    <div className={styles.menu}>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.restaurants.path}>Рестораны</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.delivery.path}>Доставка</Link>
                        </div>
                        <div className={styles.menuItem}>
                            <Link to={publicRoutes.restaurants.path}>Контакты</Link>
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.disclamer}>
                <div className={styles.disclamerItem}>
                    <a href="/confidential" target={"_blank"}>Политика конфиденциальности</a>
                </div>
                <div className={styles.disclamerItem}>© 2023, Кореана</div>
                <div className={styles.disclamerItem}>Design by Alina Yun</div>
            </div>
        </div>
        <img className={styles.footerLogo} src={logo} alt=""/>
    </div>

}
