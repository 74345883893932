import React from 'react'

import styles from './Banner.module.css';
import bannerImg from "../../../../static/images/landing/meal_landing.png";

export const Banner = () => {

    return <div className={styles.container}>
        <div className={styles.banner}>
            <div className={styles.banner__info}>
                <h1 className={styles.banner__title}>
                    Корейский летний настоящий
                </h1>
                <p className={styles.banner__text}>
                    Куксу в переводе с корейского — лапша или блюдо с лапшой. Готовится блюдо на соевом или томатном
                    бульоне. В состав входят жареная говядина, омлет, овощи и главный ингредиент —
                    тонкая пшеничная лапша.
                </p>
                <p className={styles.banner__text}>
                    Три ресторана - Кореана, Кореана Light и Kimchi to go собрались вместе и вывели идеальный баланс
                    острого, кислого и сладкого. Суп традиционно подается холодным и освежает в жаркую погоду.
                </p>
                <p className={styles.banner__text}>
                    Вы нашли настоящий куксу!
                </p>
            </div>
            <div className={styles.banner__img}>
                <img src={bannerImg} alt='banner' />
            </div>
        </div>
    </div>
}
