import {AppContext} from "../../store/context";
import styles from "./CallBack.module.css";
import stylesForm from  "../common/Form.module.css";
import InputMask from "react-input-mask";
import attach from "../../static/icons/attach.svg";
import {classNames} from "../../helper";
import {RedButton} from "../common/Buttons";
import {createRef, useContext, useEffect, useState} from "react";
import cross from "../../static/icons/cross.svg";

export const ResponsiveCallBackModal = () => {
    const {context} = useContext(AppContext)

    const fileRef = createRef();

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const [data, setData] = useState({
        name: '',
        phone: '',
        email: '',
        comment: ''
    })

    const [file, setFile] = useState(null);

    const [proceed, setProceed] = useState(false);

    const isOpen = context.modals.callBack.state.open;

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setProceed(false);
            setData({
                name: '',
                phone: '',
                email: '',
                comment: ''
            });
            setTimeout(() => {
                setOpen(isOpen);
            }, 10);
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen);
            }, 400);
        }
    }, [isOpen]);

    const onDismiss = () => {
        context.modals.callBack.dispatch({ type: 'close' });
    };

    const processForm = () => {
        if (proceed) return

        if (!data.comment || !data.phone || !data.name) {
            context.toast.error('Пожалуйста, проверьте корректность введенных данных');
            return;
        }

        const phoneUnmasked = data.phone?.match(/\d+/g).join('') || "";
        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (!data.name || data.name.length < 1) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        if (data.comment.length < 4) {
            context.toast.error('Пожалуйста, проверьте корректность введенного комментария');
            return;
        }

        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("phone", phoneUnmasked);
        formData.append("email", data.email);
        formData.append("comment", data.comment);
        formData.append("file", fileRef.current.files[0]);

        context.processor.form.callback(formData).then(res => {
            setProceed(true)

            setData({
                name: '',
                phone: '',
                email: '',
                comment: ''
            });

        }).catch(err => {
            context.toast.error(err.message)
        })
    };

    return <div
        className={classNames(styles.modal, open ? styles.modalOpen : '')}
        style={{ display: display ? 'flex' : 'none' }}
    >
        <button
            type="button"
            className={styles.cross}
            onClick={() => onDismiss()}
        >
            <img src={cross} alt="" />
        </button>
        <div className={styles.inner}>
            <div className={styles.innerTop}>
                <span className={styles.supTitle}>우리에게 편지를 보내세요</span>
                <h2 className={styles.title}>
                    Напишите нам!
                </h2>
                <p className={styles.text}>
                    Напишите нам свой вопрос или оставьте пожелание.
                    Наши специалисты службы качества свяжутся с вами в ближайшее время! <br />
                    Или свяжитесь с колл-центром по номеру
                    <a href='tel:+78126034747' className={styles.tel}>603-47-47</a>
                </p>
            </div>
            <div className={styles.innerBottom}>
                <div className={stylesForm.formHolder}>
                    <div className={stylesForm.formRow}>
                        <div className={stylesForm.formInput}>
                            <input type={'text'}
                                   disabled={proceed}
                                   placeholder={'Имя'}
                                   value={data.name}
                                   onChange={(e) => setData({...data, name: e.currentTarget.value})}
                            />
                        </div>
                    </div>
                    <div className={stylesForm.formRow}>
                        <div className={stylesForm.formInput}>
                            <InputMask
                                value={data.phone}
                                disabled={proceed}
                                formatChars={{
                                    '9': '[0-9]',
                                    '7': '[7-8]'
                                }}
                                onChange={e => setData({...data, phone: e.currentTarget.value})}
                                mask="+7 (999) 999-99-99"
                                placeholder={'Телефон'}
                                maskChar="_"/>
                        </div>
                        <div className={stylesForm.formInput}>
                            <input type={'text'} placeholder={'E-mail'}
                                   disabled={proceed}
                                   value={data.email}
                                   onChange={(e) => setData({...data, email: e.currentTarget.value})}
                            />
                        </div>
                    </div>
                    <div className={stylesForm.formRow}>
                        <div className={stylesForm.formInput}>
                        <textarea rows={8}
                                  placeholder={'Сообщение'}
                                  disabled={proceed}
                                  value={data.comment}
                                  onChange={(e) => setData({...data, comment: e.currentTarget.value})}
                        />
                        </div>
                    </div>
                    <div className={stylesForm.formUpload} onClick={() => {
                        fileRef.current.click()
                    }}>
                        <input
                            disabled={proceed}
                            accept={"image/*"} style={{width: "1px", height: "1px", visibility: "hidden"}}
                            ref={fileRef}
                            onChange={(e) => {
                                return e.target.files.length ? setFile(e.target.files[0].name) : null
                            }}
                            type="file"/>
                        <span>{file ? file : "загрузить изображение"}</span>
                        <img src={attach} alt=""/>
                    </div>
                    <div className={classNames(stylesForm.formRow, stylesForm.button)}>
                        <RedButton text={'Отправить'} onClick={() => processForm()}/>
                    </div>
                    { proceed && <SuccessTextForm /> }
                </div>
            </div>
        </div>
    </div>
}

const SuccessTextForm = () => {
    return (
        <p className={styles.textSuccess}>Спасибо! Мы скоро с Вами свяжемся</p>
    );
};