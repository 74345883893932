import React, {useContext, useRef} from "react";
import {ToastContainer} from 'react-toastify';
import {useLocation} from "react-router-dom";

import {Navbar} from '../components/common/Navbar'
import {Footer} from '../components/common/Footer'
import {ModalWrapper, Clearfix} from "../components/modals/Wrapper";
import {ResponsiveAddressModal} from "../components/modals/Address";
import {ResponsiveRestaurantModal} from "../components/modals/Restaurant";
import {ResponsivePersonalModal} from "../components/modals/Personal";
import {ResponsiveActionModal} from "../components/modals/Action";
import {ResponsiveCartModal} from "../components/modals/Cart";
import {usePageViews} from "../analytics";

import {AppContext} from "../store/context";

import styles from './MainLayout.module.css'
import logo from "../static/images/main/mobile.svg";
import arrow from "../static/images/main/arrow-down.svg";
import toggle from "../static/icons/nav-toggle.svg";
import {ResponsiveOperatingModal} from "../components/modals/Operating";
import {ResponsiveGiftCertificateModal} from "../components/modals/GiftCertificate";
import {ResponsiveCallBackModal} from "../components/modals/CallBack";

const MainLayout = ({children}) => {
    const {context} = useContext(AppContext)
    const location = useLocation()
    usePageViews();

    const ref = useRef(null)

    return (
        <React.Fragment>
            { context.settings.isMobile
            && location.pathname === "/" ? <Hero main={ref}/> : null}
            <Navbar/>
            <div className={styles.main} ref={ref}>
                {children}
            </div>
            <Footer/>
            <ModalWrapper>
                <ResponsiveCartModal/>
                <ResponsiveActionModal/>
                <ResponsiveRestaurantModal/>
                <ResponsiveAddressModal/>
                <ResponsivePersonalModal/>
                <ResponsiveOperatingModal/>
                <ResponsiveGiftCertificateModal/>
                <ResponsiveCallBackModal />
                <Clearfix/>
            </ModalWrapper>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    );
};

const Hero = ({main}) => {
    const {context} = useContext(AppContext)

    const scroll = () => {
        window.scrollTo({top: main.current.offsetTop - 95, left: 0, behavior: "smooth"});

        setTimeout(() => {
            context.common.dispatch({type: "heroShown"})
        }, 300)
    }

    return <div className={styles.hero} style={{display: 'flex'}}>
        <div className={styles.nav}>
            <img src={toggle} alt="" onClick={() => context.modals.nav.dispatch({type: "open"})}/>
        </div>
        <div className={styles.logo}>
            <img src={logo} alt=""/>
        </div>
        <div className={styles.image}>
        </div>
        <div className={styles.btn} onClick={() => scroll()}>
            <img src={arrow} alt=""/>
        </div>
    </div>
}

export default MainLayout;
