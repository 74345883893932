import React, {useContext, useState} from 'react'
import {generatePath, useHistory} from "react-router-dom";

import img1 from '../../../static/images/banner/img1.png'
import img2 from '../../../static/images/banner/img2.png'
import img3 from '../../../static/images/banner/img3.png'

import {AppContext} from "../../../store/context";

import styles from './Banner.module.css'
import {publicRoutes} from "../../../routing";
import {CallbackForm} from "../../common/Form";

export const Banner = () => {
    const {context} = useContext(AppContext);
    const [active, setActive] = useState(2);
    const history = useHistory();

    return <div className={styles.container}>
        <div className={styles.imagesSection}>
            <div className={styles.imagesSection}>
                <div className={styles.overlayTop}/>
                <div className={styles.images}>
                    <div className={`${styles.image} ${active === 0 ? styles.active : ''}`}
                         style={{backgroundImage: `url(${img1})`}}
                         onClick={() => {
                             setActive(0)
                         }}/>
                    <div className={`${styles.image} ${active === 1 ? styles.active : ''}`}
                         style={{backgroundImage: `url(${img2})`}}
                         onClick={() => {
                             setActive(1)
                         }}/>
                    <div className={`${styles.image} ${active === 2 ? styles.active : ''}`}
                         style={{backgroundImage: `url(${img3})`}}
                         onClick={() => {
                             setActive(2)
                         }}/>
                </div>
                <div className={styles.overlayBot1}/>
                <div className={styles.overlayBot2}/>
            </div>
        </div>
        <div className={styles.textSection}>
            <div className={styles.preHeading}>한국 문화 레스토랑</div>
            <h1 className={styles.heading}>
                <span className={styles.heading__red}>10 лет </span>
                любви к корейской кухне
            </h1>
            <div className={styles.text}><span>Самый простой способ прикоснуться к другой культуре – ощутить ее национальный вкус</span>
            </div>
            <div className={styles.list}>
                <div className={styles.listItem} onClick={() => {
                    history.push(generatePath(publicRoutes.restaurants.path))
                }}>
                    <div className={styles.listItemBullet}>주소</div>
                    <div className={styles.listItemText}>РЕСТОРАНЫ</div>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.listItemBullet}>식당</div>
                    <a href={"/menu"} target={"_blank"} className={styles.listItemText} rel="noopener noreferrer">
                        меню ресторана
                    </a>
                </div>
                <div className={styles.listItem}
                     onClick={() => context.modals.callBack.dispatch({type: "open"})}
                >
                    <div className={styles.listItemBullet}>연결</div>
                    <div className={styles.listItemText}>СВЯЗАТЬСЯ С НАМИ</div>
                </div>
                <div className={styles.listItem} onClick={() => {
                    history.push(generatePath(publicRoutes.delivery.path))
                }}>
                    <div className={styles.listItemBullet}>배달</div>
                    <div className={styles.listItemText}>Заказать доставку</div>
                </div>
                <div className={styles.listItem}
                     onClick={() => context.modals.restaurant.dispatch(
                         {type: "show", payload: context.common.state.restaurants[0]}
                     )}
                >
                    <div className={styles.listItemBullet}>예약</div>
                    <div className={styles.listItemText}>ЗАБРОНИРОВАТЬ СТОЛ</div>
                </div>
            </div>
        </div>
    </div>
}