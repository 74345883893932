import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-spring-bottom-sheet/dist/style.css'
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
