import MainLayout from "../layout/MainLayout"
import DeliveryLayout from "../layout/DeliveryLayout";

import Main from "../views/Main"
import Page404 from "../views/Page404"
import Delivery from "../views/delivery/Main";
import DeliveryCategory from "../views/delivery/Category";
import Order from "../views/delivery/Order";
import Conditions from "../views/delivery/Conditions";
import Restaurants from "../views/content/Restaurants";
import Blog from "../views/blog/Blog";
import Actions from "../views/delivery/Actions";
import Restaurant from "../views/content/Restaurant";
import Personal from "../views/personal/Personal";
import Article from "../views/blog/Article";
import Vacancies from "../views/content/Vacancies";
import About from "../views/content/About";
import FeedbackDelivery from "../views/forms/FeedbackDelivery";
import FeedbackRestaurant from "../views/forms/FeedbackRestaurant";
import AgentRequest from "../views/forms/AgentRequest";
import AgentDelivery from "../views/forms/AgentDelivery";
import AgentRestaurant from "../views/forms/AgentRestaurant";
import Search from "../views/Search";
import KoreanProducts from "../views/delivery/KoreanProducts";
import Table from "../views/forms/Table";
import KuksuMania from "../views/content/KuksuMania";
import Lubov from "../views/Lubov";
import Landing from "../views/Landing";
import Menu from "../views/Menu";
import FiveLegends from "../views/content/FiveLegends";

export const publicRoutes = {
    main: {
        path: "/",
        layout: MainLayout,
        component: Main,
    },
    notFound: {
        path: "/404",
        layout: MainLayout,
        component: Page404
    },
    terms: {
        path: "/payment-and-delivery",
        layout: MainLayout,
        component: Conditions
    },
    delivery: {
        path: "/delivery",
        layout: DeliveryLayout,
        component: Delivery
    },
    deliveryActions: {
        path: "/skidki",
        layout: MainLayout,
        component: Actions
    },
    deliveryCategoryKoreanProducts: {
        path: ["/korean-market", "/korean-market/:product"],
        layout: DeliveryLayout,
        component: KoreanProducts
    },
    deliveryCategory: {
        path: ["/delivery/:slug", "/delivery/:slug/:product"],
        layout: DeliveryLayout,
        component: DeliveryCategory
    },
    order: {
        path: "/order/:hash",
        layout: MainLayout,
        component: Order,
    },
    about: {
        path: "/about",
        layout: MainLayout,
        component: About
    },
    contacts: {
        path: "/contacts",
        layout: MainLayout,
        component: Restaurants
    },
    blog: {
        path: "/blog",
        layout: MainLayout,
        component: Blog
    },
    blogArticle: {
        path: "/blog/:slug",
        layout: MainLayout,
        component: Article
    },
    restaurants: {
        path: "/restaurants",
        layout: MainLayout,
        component: Restaurants
    },
    restaurant: {
        path: "/restaurants/:slug",
        layout: MainLayout,
        component: Restaurant
    },
    vacancies: {
        path: "/vacancies",
        layout: MainLayout,
        component: Vacancies
    },
    personal: {
        path: "/personal",
        layout: MainLayout,
        component: Personal
    },
    search: {
        path: "/search",
        layout: MainLayout,
        component: Search
    },
    //forms
    agent: {
        path: "/agent",
        layout: MainLayout,
        component: AgentRequest
    },
    agentDelivery: {
        path: "/agent-delivery",
        layout: MainLayout,
        component: AgentDelivery
    },
    agentRestaurant: {
        path: "/agent-restaurant",
        layout: MainLayout,
        component: AgentRestaurant
    },
    feedback: {
        path: "/otzyv",
        layout: MainLayout,
        component: FeedbackRestaurant
    },
    feedbackDelivery: {
        path: "/otzyv-delivery",
        layout: MainLayout,
        component: FeedbackDelivery
    },
    table: {
        path: "/table",
        layout: MainLayout,
        component: Table
    },
    lubov: {
        path: "/10-let-lubvi",
        layout: MainLayout,
        component: Lubov,
    },
    landing: {
        path: "/kuksu",
        layout: MainLayout,
        component: Landing,
    },
    menu: {
        path: "/menu",
        layout: MainLayout,
        component: Menu,
    },
    // kuksuMania: {
    //     path: "/kuksu-mania",
    //     layout: LandingLayout,
    //     component: KuksuMania
    // }
    fiveLegends: {
        path: '/5-legends',
        layout: MainLayout,
        component: FiveLegends,
    }
};

export default [
    ...Object.values(publicRoutes)
]
