import React from 'react'
import {AppContext} from "../../store/context";
import styles from './Blog.module.css'
import back from "../../static/icons/conditions/back.svg";
import {LoaderFullsize} from "../../components/common/Loader";
import {generatePath} from "react-router-dom";
import {publicRoutes} from "../../routing";
import {classNames} from "../../helper";
import {STATIC_HOST_ARTICLE} from "../../const";

export default class Blog extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false,
            articles: [],
            categories: [],
            filter: null,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.screen.blog().then(res => {
            this.setState({
                articles: res.articles,
                categories: res.categories,
                loaded: true
            })
        })
    }

    navigate(el) {
        this.props.history.push(generatePath(publicRoutes.blogArticle.path, {slug: el.slug}))
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <div className={styles.titleText}>Блог</div>
            </div>
            {this.state.loaded ? <>
                    <div className={styles.tags}>
                        {this.state.categories.map(el => {
                            return <div
                                className={classNames(styles.tag, el.id === this.state.filter?.id ? styles.active : '')}
                                onClick={() => this.setState({filter: el.id === this.state.filter?.id ? null : el})}>
                                {el.name}
                            </div>
                        })}
                    </div>
                    <div className={styles.grid}>
                        {this.state.articles.map(el => {
                            if (this.state.filter) {
                                if (!el.categories.find(e => this.state.filter.id === e.id)) {
                                    return null
                                }
                            }

                            return <div className={styles.card} onClick={() => {
                                this.navigate(el)
                            }}>
                                <div className={styles.image}>
                                    <img src={STATIC_HOST_ARTICLE + el.image?.source} alt={el.image?.alt}/>
                                </div>
                                <div className={styles.body}>
                                    <div className={styles.cardTags}>{el.categories.map(el => {
                                        return <div className={styles.cardTag}>{el.name}</div>
                                    })}</div>
                                    <div className={styles.articleTitle}>{el.title}</div>
                                    <div className={styles.preview}>{el.preview}</div>
                                </div>
                            </div>
                        })}
                    </div>
                </>
                : <LoaderFullsize/>}
        </div>
    }
}

Blog.contextType = AppContext