import React, {useContext, useEffect, useRef, useState} from 'react'

import styles from './Team.module.css'

import {AppContext} from "../../../../store/context";
import arrowDown from "../../../../static/icons/arrow_down_hits.svg";

export const Team = () => {
    const {context} = useContext(AppContext)

    const [team, setTeam] = useState(context.common.state.team)

    const [selected, setSelected] = useState(context.common.state.team[0])
    const [pos, setPos] = useState(0);
    const ref = useRef()

    useEffect(() => {
        setSelected(team[0])
        setTeam(team.slice(1))
    }, [])

    const changeSlide = (el) => {
        const it = team
        const i = it.findIndex((e) => e.id === el.id)

        it[i] = selected

        setTeam(it)
        setSelected(el)
    }

    const scroll = () => {
        const step = 292 + 15
        const maxPos = step * 4

        const newPos = pos + step

        setPos(newPos < maxPos ? newPos : 0)

        ref?.current?.scrollTo({
            top: newPos < maxPos ? newPos : 0,
            behavior: "smooth"
        })
    }

    return <div className={styles.container}>
        <div className={styles.title}>
            <h2 className={styles.head}>наши основатели</h2>
            <div className={styles.subtitleWrapper}>
                <span className={styles.subtitle}>우리 창립 멤버들</span>
            </div>
        </div>
        <div className={styles.inner}>
            <div className={styles.preview}>
                <div className={styles.previewWrapper}>
                    <div className={styles.previewImage}>
                        <img src={selected?.img}
                             alt={selected?.name}/>
                    </div>
                    <div className={styles.previewTextHolder}>
                        <div className={styles.previewTitle}>
                            <div className={styles.previewTitleText}>{selected?.name}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.gallery}>
                <div className={styles.galleryWrapper}>
                    <div className={styles.galleryInner} ref={ref}>
                        {team.map(el => {
                            return <div className={styles.galleryImage} onClick={() => changeSlide(el)}>
                                <img src={el.img} alt={el.name}/>
                                <div className={styles.galleryImageText}>{ el.name }</div>
                            </div>
                        })}
                    </div>
                    {context.settings.isMobile
                        ? <div onClick={() => scroll()} className={styles.galleryScroll}>
                            <img src={arrowDown} alt=""/>
                        </div>
                        : null}
                </div>
            </div>
            <div className={styles.previewSubtitle}>
                {selected?.text?.map(el => {
                    return <p><b>{el.title}</b> {el.description}</p>
                })}
            </div>
        </div>
    </div>
}
