import React, {useContext} from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

import {Head} from "../components/common/Seo";
import {AppContext} from "../store/context";
import {LoaderFullsize} from "../components/common/Loader";
import {CustomPage} from "../views/CustomPage";

import routes from "./index";

const Routes = () => {
    const {context} = useContext(AppContext);

    return context.common.state.loaded ? <Router>
        <Head/>
        <Switch>
            {RouteMap()}
            <Route path={'/*'} exact render={(props) => (
                <CustomPage {...props}/>
            )}/>
        </Switch>
    </Router> : <LoaderFullsize/>
};

const RouteMap = () => {
    let map = [];

    routes.forEach((el, index) => {
        map.push(componentRoute(el, index));
    });

    return map
};

const componentRoute = ({layout: Layout, component: Component, path}, index) => {
    return Component ? (<Route key={index} path={path} exact render={(props) => (
        <Layout><Component {...props} /></Layout>
    )}/>) : null;
};

export default Routes;
