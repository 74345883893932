import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/address`;

export class Address extends Base {
    checkAddress(data) {
        return this.fetchJSON(`${BASE_URL}/check`, {
            method: "post",
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }

    setAddress(data) {
        return this.fetchJSON(`${BASE_URL}/`, {
            method: "POST",
            body: JSON.stringify(data)
        }).then(response => {
            return response.data
        })
    }
}