import React, {createRef} from "react";
import styles from "./Vacancies.module.css";
import back from "../../static/icons/conditions/back.svg";
import {AppContext} from "../../store/context";
import {LoaderFullsize} from "../../components/common/Loader";
import {RedButton} from "../../components/common/Buttons";

import first from '../../static/images/vacancies/first.png'
import second from '../../static/images/vacancies/second.png'
import arrow from '../../static/icons/vacancies/arrow.svg'
import {classNames} from "../../helper";
import {Collapse} from "react-collapse";
import {VacancyForm} from "../../components/common/Form";

export default class Vacancies extends React.Component {
    constructor() {
        super()
        this.block = createRef()

        this.state = {
            loaded: false,
            vacancies: [],
            selected: null
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.screen
            .vacancies()
            .then(res => this.setState({
                vacancies: res.vacancies,
                selected: res.vacancies[0],
                loaded: true
            }))
    }

    slide() {
        window.scrollTo({top: this.block.current.offsetTop, left: 0, behavior: "smooth"});
    }

    render() {
        return this.state.loaded ? <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <div className={styles.titleText}>присоединяйтесь к нашей команде</div>
            </div>
            <div className={styles.inner}>
                <div className={styles.heading}>
                    <div className={styles.images}>
                        <img src={first} alt=""/>
                        <img src={second} alt=""/>
                    </div>
                    <div className={styles.headingText}>
                        <div className={styles.headingTextAbout}>Мы черпаем вдохновение в людях, которые нас окружают.
                            С любовью относимся к нашим сотрудникам, поэтому гарантируем:
                        </div>
                        <div className={styles.headingList}>
                            <div className={styles.headingListItem}>Карьерный рост</div>
                            <div className={styles.headingListItem}>Комфортные условия труда</div>
                            <div className={styles.headingListItem}>Долгосрочное сотрудничество</div>
                            <div className={styles.headingListItem}>Своевременную и достойную зарплату</div>
                        </div>
                        <div className={styles.headingButton}>
                            <RedButton text={'открытые вакансии'} onClick={() => {
                                this.slide()
                            }}/>
                        </div>
                    </div>
                </div>
                <div className={styles.vacancies} ref={this.block}>
                    <div className={styles.list}>
                        {this.state.vacancies.map(el => {
                            return <div
                                className={classNames(styles.listItem, this.state.selected.id === el.id ? styles.active : '')}
                                onClick={() => {
                                    this.setState({selected: el})
                                }}>
                                <div className={styles.listItemHead}>
                                    <div className={styles.listItemTitle}>{el.title}</div>
                                    <div className={styles.listItemIcon}>
                                        <img src={arrow} alt=""/>
                                    </div>
                                </div>
                                <div className={styles.listItemBody}>
                                    {el.preview}
                                </div>
                                {this.context.context.settings.isMobile ? <Collapse
                                    theme={{collapse: styles.collapse}}
                                    isOpened={this.state.selected.id === el.id}>
                                    <Vacancy vacancy={el}/>
                                </Collapse> : null}
                            </div>
                        })}
                    </div>
                    <div className={styles.current}>
                        <Vacancy vacancy={this.state.selected}/>
                    </div>
                </div>
                <div className={styles.form}>
                    <div className={styles.formInner}>
                        <div className={styles.formVacancy}>
                            <VacancyForm />
                        </div>
                        <div className={styles.formDesc}>
                            <div className={styles.formHeading}>
                                Не нашли подходящую вакансию?
                            </div>
                            <div className={styles.formText}>
                                Если Вы не нашли подходящей вакансии, отправьте резюме нам на почту и мы обязательно
                                свяжемся с Вами в ближайшее время.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div> : <LoaderFullsize/>
    }
}

const Vacancy = ({vacancy}) => {
    return <div className={styles.vacancy}>
        <div className={styles.description}
             dangerouslySetInnerHTML={{__html: vacancy.description}}/>
        <div className={styles.textBlock}>
            <div className={styles.blockTitle}>Требования</div>
            <div className={styles.blockList}>
                <ul>
                    {Object.values(vacancy.requirements).map(el => {
                        return <li>{el}</li>
                    })}
                </ul>
            </div>
        </div>
        <div className={styles.textBlock}>
            <div className={styles.blockTitle}>Условия</div>
            <div className={styles.blockList}>
                <ul>
                    {Object.values(vacancy.terms).map(el => {
                        return <li>{el}</li>
                    })}
                </ul>
            </div>
        </div>
        <div className={styles.textBlock}>
            <div className={styles.blockTitle}>Обязанности</div>
            <div className={styles.blockList}>
                <ul>
                    {Object.values(vacancy.duties).map(el => {
                        return <li>{el}</li>
                    })}
                </ul>
            </div>
        </div>
        <div className={styles.skillsBlock}>
            <div className={styles.blockTitle}></div>
            <div className={styles.blockList}>
                {Object.values(vacancy.skills).map(el => {
                    return <div className={styles.skill}>{el}</div>
                })}
            </div>
        </div>
        <VacancyForm position={vacancy?.title}/>
    </div>
}

Vacancies.contextType = AppContext;