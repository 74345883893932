import React from "react";

import {AppContext} from "../../store/context";
import {Loader} from "../../components/common/Loader";
import {ActionCard} from "../../components/sections/catalog/Action";

import back from "../../static/icons/conditions/back.svg";

import styles from "./Actions.module.css";

export default class Actions extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false,
            actions: [],
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.screen.actions().then(res => {
            this.setState({actions: res.actions, loaded: true})
        })

    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <div className={styles.titleText}>акции и спецпредложения</div>
            </div>
            <div className={styles.inner}>
                {this.state.loaded ?
                    <div className={styles.grid}>
                        {this.state.actions.map(el => (<ActionCard big={true} action={el}/>))}
                    </div>
                    : <Loader className={styles.loader}/>}
            </div>
        </div>
    }

}

Actions.contextType = AppContext