import React, {createRef} from "react";
import InputMask from "react-input-mask";

import {AppContext} from "../../store/context";
import {RedButton} from "../../components/common/Buttons";
import {classNames} from "../../helper";
import {Checkbox, Question, QuestionStars, QuestionTextarea, QuestionFilesImages} from "../../components/common/Form";

import back from '../../static/icons/back.svg'
import image from '../../static/images/forms/agent-request.png'

import styles from "./AgentRestaurant.module.css";


export default class AgentRestaurant extends React.Component {
    constructor() {
        super();
        this.form = createRef()

        this.state = {
            proceed: false,
            step: 1,

            name: null,
            phone: null,
            restaurant: null,
            waiter: null,
            receipt: null,

            //first appearance
            checkbox_0_1: null,
            checkbox_0_2: null,
            checkbox_0_3: null,
            checkbox_0_4: null,

            //waiter
            checkbox_1_1: null,
            checkbox_1_2: null,
            checkbox_1_3: null,
            checkbox_1_4: null,

            //service
            checkbox_2_1: null,
            checkbox_2_2: null,
            checkbox_2_3: null,
            checkbox_2_4: null,

            //food
            checkbox_3_1: null,
            checkbox_3_2: null,
            checkbox_3_3: null,
            bluda: '',

            //overall
            checkbox_4_1: null,
            checkbox_4_2: null,
            checkbox_4_3: null,
            checkbox_4_4: null,

            atmosphere: 1,
            recommend: 1,
            obsluzh: '',

            files: null,
        }
    }

    prevStep() {
        window.scrollTo({top: this.form.current.offsetTop - 20, left: 0, behavior: "smooth"});

        this.setState({step: this.state.step - 1})
    }

    progressClass() {
        switch (this.state.step) {
            case 1:
                return classNames(styles.progress, styles.progress_1)
            case 2:
                return classNames(styles.progress, styles.progress_2)
            case 3:
                return classNames(styles.progress, styles.progress_3)
            case 4:
                return classNames(styles.progress, styles.progress_4)
            case 5:
                return classNames(styles.progress, styles.progress_5)
            case 6:
                return classNames(styles.progress, styles.progress_6)
            case 7:
                return classNames(styles.progress, styles.progress_7)
        }
    }

    nextStep() {
        switch (this.state.step) {
            case 1:
                const phoneUnmasked = this.state.phone?.match(/\d+/g).join('') || "";
                if (phoneUnmasked.length < 11) {
                    this.context.context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
                    return;
                }
                if (!this.state.waiter) {
                    this.context.context.toast.error('Пожалуйста, проверьте корректность имени официанта');
                    return;
                }

                if (!this.state.name) {
                    this.context.context.toast.error('Пожалуйста, проверьте корректность введенного имени');
                    return;
                }

                if (!this.state.restaurant) {
                    this.context.context.toast.error('Пожалуйста, укажите ресторан');
                    return;
                }

                if (!this.state.receipt) {
                    this.context.context.toast.error('Пожалуйста, укажите номер чека');
                    return;
                }

                break;
            case 2:
                if (
                    this.state.checkbox_0_1 === null ||
                    this.state.checkbox_0_2 === null ||
                    this.state.checkbox_0_3 === null ||
                    this.state.checkbox_0_4 === null

                ) {
                    this.context.context.toast.error('Пожалуйста, укажите ответы на все вопросы');
                    return;
                }
                break;
            case 3:
                if (
                    this.state.checkbox_1_1 === null ||
                    this.state.checkbox_1_2 === null ||
                    this.state.checkbox_1_3 === null ||
                    this.state.checkbox_1_4 === null
                ) {
                    this.context.context.toast.error('Пожалуйста, укажите ответы на все вопросы');
                    return;
                }
                break;
            case 4:
                if (
                    this.state.checkbox_2_1 === null ||
                    this.state.checkbox_2_2 === null ||
                    this.state.checkbox_2_3 === null ||
                    this.state.checkbox_2_4 === null
                ) {
                    this.context.context.toast.error('Пожалуйста, укажите ответы на все вопросы');
                    return;
                }
                break;
            case 5:
                if (
                    this.state.checkbox_3_1 === null ||
                    this.state.checkbox_3_2 === null ||
                    this.state.checkbox_3_3 === null
                ) {
                    this.context.context.toast.error('Пожалуйста, укажите ответы на все вопросы');
                    return;
                }
                break;
            case 6:
                if (
                    this.state.checkbox_4_1 === null ||
                    this.state.checkbox_4_2 === null ||
                    this.state.checkbox_4_3 === null ||
                    this.state.checkbox_4_4 === null
                ) {
                    this.context.context.toast.error('Пожалуйста, укажите ответы на все вопросы');
                    return;
                }
                break;
        }


        window.scrollTo({top: this.form.current.offsetTop - 20, left: 0, behavior: "smooth"});

        this.setState({step: this.state.step + 1})
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    send() {
        const formData = new FormData();

        for (let prop in this.state) {
            if (prop !== 'files') {
                formData.append(prop, this.state[prop]);
            }

            if (prop === 'files') {
                const files = this.state.files;
                if (files.length > 0) {
                    files.forEach((file, index) => {
                        formData.append(`file_${index}`, file);
                    });
                } else {
                    formData.append('file_0', '');
                }
            }
        }

        this.context.context.processor.form
            .agentReport(formData)
            .then(res => this.setState({proceed: true}))
            .catch(err => this.context.context.toast.error(err.message))
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleText}>тайный агент: рестораны</div>
                <div className={styles.titleSub}>Ваши идеи помогают нам улучшать сервис</div>
            </div>
            <div className={styles.inner}>
                {this.state.proceed ?
                    <div className={styles.description}>
                        <div className={styles.image}>
                            <img src={image} alt=""/>
                        </div>
                        <div className={styles.typo}>
                            <div className={styles.head}>
                                <span>Спасибо, что заполнили отчёт по тайному агенту!</span>
                            </div>
                            <div className={styles.text}>
                                В течение двух дней с Вами свяжется наш менеджер службы качества.
                            </div>
                            <div className={styles.button}>
                                <RedButton onClick={() => this.props.history.push("/")} text={"на главную"}/>
                            </div>
                        </div>
                    </div>
                    : <div className={styles.form} ref={this.form}>
                        <div className={styles.formSteps}>
                            <div className={styles.steps}>
                                <div className={styles.stepTitle}>заполнено {this.state.step - 1}/7</div>
                                <div className={this.progressClass()}/>
                                <div className={styles.stepList}>
                                    <div
                                        className={classNames(styles.step, this.state.step === 1 ? styles.active : null)}>
                                        Общая информация
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 2 ? styles.active : null)}>
                                        Первое впечатление
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 3 ? styles.active : null)}>
                                        Работа официанта
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 4 ? styles.active : null)}>
                                        Сервис
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 5 ? styles.active : null)}>
                                        Качество блюд и напитков
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 6 ? styles.active : null)}>
                                        Общее впечатление
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 7 ? styles.active : null)}>
                                        Атмосфера и рекомендации
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.survey}>
                            <div className={classNames(styles.block, this.state.step === 1 ? styles.active : '')}>
                                <div className={styles.common}>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionRest)}>
                                        <div className={styles.commonTitle}>Адрес ресторана *</div>
                                        <div className={styles.commonAnswer}>
                                            {this.context.context.common.state.restaurants.map(el => {
                                                return <div className={styles.commonCheckbox}>
                                                    <Checkbox checked={this.state.restaurant === el.name}
                                                              onClick={() => this.setState({restaurant: el.name})}
                                                              text={el.name}/>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionName)}>
                                        <div className={styles.commonTitle}>Ваше имя *</div>
                                        <div className={styles.commonAnswer}>
                                            <input type="text" value={this.state.name} className={styles.input}
                                                   placeholder={"Имя"}
                                                   onChange={(e) => this.setState({name: e.currentTarget.value})}/>
                                        </div>
                                    </div>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionPhone)}>
                                        <div className={styles.commonTitle}>Номер телефона *</div>
                                        <div className={styles.commonAnswer}>
                                            <InputMask className={styles.input}
                                                       value={this.state.phone}
                                                       formatChars={{
                                                           '9': '[0-9]',
                                                           '7': '[7-8]'
                                                       }}
                                                       readonly={true}
                                                       mask="+7 (999) 999-99-99"
                                                       placeholder={'Номер телефона'}
                                                       onChange={(e) => this.setState({phone: e.currentTarget.value})}
                                                       maskChar="_"/>
                                        </div>
                                    </div>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionWaiter)}>
                                        <div className={styles.commonTitle}>имя официанта *</div>
                                        <div className={styles.commonAnswer}>
                                            <input type="text" value={this.state.waiter} className={styles.input}
                                                   placeholder={"Имя официанта"}
                                                   onChange={(e) => this.setState({waiter: e.currentTarget.value})}/>
                                        </div>
                                    </div>
                                    <div className={classNames(styles.commonQuestion, styles.commonQuestionReceipt)}>
                                        <div className={styles.commonTitle}>номер чека*</div>
                                        <div className={styles.commonAnswer}>
                                            <input type="text" value={this.state.receipt} className={styles.input}
                                                   placeholder={"Номер чека"}
                                                   onChange={(e) => this.setState({receipt: e.currentTarget.value})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.nextButton}>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 2 ? styles.active : '')}>
                                <div className={styles.blockTitle}>Первое впечатление</div>
                                <div className={styles.questions}>
                                    <Question
                                        text={"При входе Вас поприветсвовали с улыбкой на корейском языке (\"Аннён хасеё\")"}
                                        value={this.state.checkbox_0_1}
                                        onNo={() => this.setState({checkbox_0_1: 0})}
                                        onYes={() => this.setState({checkbox_0_1: 1})}
                                    />
                                    <Question
                                        text={"Сотрудник ресторана проводил Вас до столика"}
                                        value={this.state.checkbox_0_2}
                                        onNo={() => this.setState({checkbox_0_2: 0})}
                                        onYes={() => this.setState({checkbox_0_2: 1})}
                                    />
                                    <Question
                                        text={"Меню принесли быстро ( в течение 2 минут)"}
                                        value={this.state.checkbox_0_3}
                                        onNo={() => this.setState({checkbox_0_3: 0})}
                                        onYes={() => this.setState({checkbox_0_3: 1})}
                                    />
                                    <Question
                                        text={"Официант представился по имени"}
                                        value={this.state.checkbox_0_4}
                                        onNo={() => this.setState({checkbox_0_4: 0})}
                                        onYes={() => this.setState({checkbox_0_4: 1})}
                                    />

                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 3 ? styles.active : '')}>
                                <div className={styles.blockTitle}>Работа официанта</div>
                                <div className={styles.questions}>
                                    <Question
                                        text={"Официант предложил напиток (если Вы выбрали напиток раньше, чем официант успел его предложить отметьте “Да”)"}
                                        value={this.state.checkbox_1_1}
                                        onNo={() => this.setState({checkbox_1_1: 0})}
                                        onYes={() => this.setState({checkbox_1_1: 1})}
                                    />
                                    <Question
                                        text={"Официант спрашивал пожелания и давал адресные рекоммендации в выборе блюд"}
                                        value={this.state.checkbox_1_2}
                                        onNo={() => this.setState({checkbox_1_2: 0})}
                                        onYes={() => this.setState({checkbox_1_2: 1})}
                                    />
                                    <Question
                                        text={"Официант уточнил как подавать блюда (по готовности или по Вашему предпочтению)"}
                                        value={this.state.checkbox_1_3}
                                        onNo={() => this.setState({checkbox_1_3: 0})}
                                        onYes={() => this.setState({checkbox_1_3: 1})}
                                    />
                                    <Question
                                        text={"Официант продемонстрировал хорошее знание меню"}
                                        value={this.state.checkbox_1_4}
                                        onNo={() => this.setState({checkbox_1_4: 0})}
                                        onYes={() => this.setState({checkbox_1_4: 1})}
                                    />
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 4 ? styles.active : '')}>
                                <div className={styles.blockTitle}>Сервис</div>
                                <div className={styles.questions}>
                                    <Question
                                        text={"Время ожидания блюд и напитков было комфортным"}
                                        value={this.state.checkbox_2_1}
                                        onNo={() => this.setState({checkbox_2_1: 0})}
                                        onYes={() => this.setState({checkbox_2_1: 1})}
                                    />
                                    <Question
                                        text={"Пустая посуда, использованные салфетки и приборы убирались со стола быстро (до 3 минут)"}
                                        value={this.state.checkbox_2_2}
                                        onNo={() => this.setState({checkbox_2_2: 0})}
                                        onYes={() => this.setState({checkbox_2_2: 1})}
                                    />

                                    <Question
                                        text={"Когда Вы захотели попросить счет, официант достаточно быстро принес его (в течение 5 минут)"}
                                        value={this.state.checkbox_2_3}
                                        onNo={() => this.setState({checkbox_2_3: 0})}
                                        onYes={() => this.setState({checkbox_2_3: 1})}
                                    />
                                    <Question
                                        text={"Счет забрали и вынесли сдачу достаточно быстро (в течение 3 минут)"}
                                        value={this.state.checkbox_2_4}
                                        onNo={() => this.setState({checkbox_2_4: 0})}
                                        onYes={() => this.setState({checkbox_2_4: 1})}
                                    />
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 5 ? styles.active : '')}>
                                <div className={styles.blockTitle}>Оцените качества блюд и напитков</div>
                                <div className={styles.questions}>
                                    <Question
                                        text={"Все заказанные блюда понравились на вкус, внешний вид, были горячими"}
                                        value={this.state.checkbox_3_1}
                                        onNo={() => this.setState({checkbox_3_1: 0})}
                                        onYes={() => this.setState({checkbox_3_1: 1})}
                                    />

                                    <Question
                                        text={"Вся посуда была чистая, без сколов и разводов"}
                                        value={this.state.checkbox_3_2}
                                        onNo={() => this.setState({checkbox_3_2: 0})}
                                        onYes={() => this.setState({checkbox_3_2: 1})}
                                    />
                                    <Question
                                        text={"Все блюда и  напитки из меню были в наличии"}
                                        value={this.state.checkbox_3_3}
                                        onNo={() => this.setState({checkbox_3_3: 0})}
                                        onYes={() => this.setState({checkbox_3_3: 1})}
                                    />

                                    <QuestionTextarea text={"Комментарии к качеству блюд и напитков"}
                                                      value={this.state.bluda}
                                                      onChange={(e) => this.setState({bluda: e.currentTarget.value})}/>

                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 6 ? styles.active : '')}>
                                <div className={styles.blockTitle}>Общее впечатление</div>
                                <div className={styles.questions}>
                                    <Question
                                        text={"Официант поинтересовался все ли Вам понравилось, при обслуживании Вам уделяли достаточно внимания, работники ресторана были внимательны и вежливы"}
                                        value={this.state.checkbox_4_1}
                                        onNo={() => this.setState({checkbox_4_1: 0})}
                                        onYes={() => this.setState({checkbox_4_1: 1})}
                                    />
                                    <Question
                                        text={"Менеджер работал в зале. Например: общался с гостями, лично встречал Гостей, помогал в работе официантам, направлял их"}
                                        value={this.state.checkbox_4_2}
                                        onNo={() => this.setState({checkbox_4_2: 0})}
                                        onYes={() => this.setState({checkbox_4_2: 1})}
                                    />
                                    <Question
                                        text={"У всех сотрудников ресторана был опрятный внешний вид"}
                                        value={this.state.checkbox_4_3}
                                        onNo={() => this.setState({checkbox_4_3: 0})}
                                        onYes={() => this.setState({checkbox_4_3: 1})}
                                    />
                                    <Question
                                        text={"При выходе кто-либо из сотрудников попрощался с Вами"}
                                        value={this.state.checkbox_4_4}
                                        onNo={() => this.setState({checkbox_4_4: 0})}
                                        onYes={() => this.setState({checkbox_4_4: 1})}
                                    />
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 7 ? styles.active : '')}>
                                <div className={styles.questions}>
                                    <QuestionStars value={this.state.atmosphere}
                                                   max={5}
                                                   onSelect={(val) => this.setState({atmosphere: val})}
                                                   text={"Оцените атмосферу в в зале, чистоту, интерьер"}/>
                                    <QuestionStars value={this.state.recommend}
                                                   max={10}
                                                   onSelect={(val) => this.setState({recommend: val})}
                                                   text={"Оцените, насколько вероятно, что Вы порекомендуете заведение своим друзьями знакомым"}/>
                                    <QuestionTextarea text={"Комментарии к качеству обслуживания"}
                                                      value={this.state.obsluzh}
                                                      onChange={(e) => this.setState({obsluzh: e.currentTarget.value})}/>
                                    <QuestionFilesImages onChange={(files) => this.setState({files: files})} />
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack, styles.finalButton)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.send()} text={"завершить отчет"}/>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    }
}

AgentRestaurant.contextType = AppContext;
