import React from 'react'

import { AppContext } from "../store/context";
import { Banner } from "../components/sections/landing/Banner/Banner";
import { Restaurants } from "../components/sections/landing/Restaurants/Restaurants";
import { Merch } from "../components/sections/landing/Merch/Merch";
import { Description } from "../components/sections/landing/Description/Description";
import { Kuksumania } from "../components/sections/landing/Kuksumania/Kuksumania";
import {PhotoSlider} from "../components/sections/landing/PhotoSlider/PhotoSlider";
import {Kuksuday} from "../components/sections/landing/Kuksuday/Kuksuday";
import {Prizes} from "../components/sections/landing/Prizes/Prizes";

export default class Lubov extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <>
            <Banner />
            <Description />
            <Kuksumania />
            <Merch />
            <Prizes />
            <Restaurants />
            <Kuksuday />
            <PhotoSlider />
       </>
    }
}

Lubov.contextType = AppContext;
